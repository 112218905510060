import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "snackbar__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_LKFButton = _resolveComponent("LKFButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`snackbar snackbar--${_ctx.type}`)
  }, [
    (_ctx.iconName !== '')
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          "icon-name": _ctx.iconName,
          class: "snackbar__icon"
        }, null, 8, ["icon-name"]))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.message), 1),
    (_ctx.buttonText)
      ? (_openBlock(), _createBlock(_component_LKFButton, {
          key: 1,
          variant: "ghost",
          onClick: _ctx.buttonCallback
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ], 2))
}