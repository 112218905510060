<template>
  <div
    ref="el"
    class="lkf-select"
    :class="[
      {'lkf-select--disabled': disabled},
      {'lkf-select--small': small}
    ]"
  >
    <LKFInput
      :caption-text="captionText"
      :error-text="errorMessage"
      :icon-after="getIcon"
      :icon-before="iconBefore"
      :label-text="labelText"
      :name="selectName"
      :no-border="noBorder"
      :placeholder="placeholder.toString()"
      :read-only="notSearchable"
      :required="required"
      :disabled="disabled"
      :pattern="pattern"
      :model-value="inputValue !== null ? inputValue : chosenOption[displayProperty]?.toString()"
      class="lkf-select__top"
      @click="toggleList()"
      @input="inputChange"
    />
    <input
      v-if="returnProperty !== 'object'"
      :name="selectName + '_value'"
      :value="chosenOption[returnProperty]"
      type="hidden"
    >
    <ul
      v-show="isOpen && hasResults"
      class="lkf-select__dropdown"
    >
      <li
        v-for="item in displayOptionsArray"
        v-show="item.visible || typeof item.visible === 'undefined'"
        :key="item[returnProperty] && '' || ''"
        :class="['lkf-select__dropdown__item',
                 {'lkf-select__dropdown__item--selected': item === chosenOption }]"
        @click="selectItem(item)"
      >
        <slot
          name="li-icon-before"
          :displayValue="item[displayProperty]"
          :returnValue="getReturnValue(item)"
        >
          {{ item[displayProperty] }}
        </slot>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, ref, onMounted, PropType, Ref, computed, watch, onBeforeUnmount, nextTick,
} from 'vue';
import { useI18n } from 'vue-i18n';
import LKFInput from '@/components/LKFInput.vue';
import { SelectOption } from '@/@types/selectOptions';

export default defineComponent({
  name: 'LKFSelect',
  components: {
    LKFInput,
  },
  props: {
    selectName: {
      type: String,
      required: true,
    },
    labelText: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    captionText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: [String, Number],
      default: () => {
        const { t } = useI18n();
        return `-${t('select.choose')}-`;
      },
    },
    defaultOption: {
      type: Object as PropType<SelectOption>,
      default: null,
    },
    returnProperty: {
      type: String,
      default: 'value',
    },
    displayProperty: {
      type: String,
      default: 'label',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    iconBefore: {
      type: String,
      default: '',
    },
    iconBeforeClass: {
      type: String,
      default: '',
    },
    iconAfter: {
      type: String,
      default: 'chevron',
    },
    notSearchable: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    pattern: {
      type: String,
      default: '',
    },
    sortTypeIncludes: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const isOpen = ref(false);
    const chosenOption = ref<SelectOption>(props.defaultOption ?? {});
    const displayOptionsArray = ref<SelectOption[]>(props.options as SelectOption[]);
    const errorMessage = ref('');
    const inputValue: Ref<string | null> = ref(null);
    const el: Ref<HTMLDivElement | null> = ref(null);
    const hasResults = computed(() => displayOptionsArray.value.some(
      (option: SelectOption) => option.visible || typeof option.visible === 'undefined',
    ));

    const updateErrorMessage = (): void => {
      if (!hasResults.value) {
        errorMessage.value = t('select.noneFound');
      } else if (
        props.required
        && (
          (inputValue.value === null && !chosenOption.value[props.displayProperty])
        )
      ) {
        isOpen.value = true;
        errorMessage.value = t('general.fieldIsRequired');
        emit('change', '');
      } else if (
        props.required
        && (inputValue.value !== null && !chosenOption.value[props.displayProperty])
      ) {
        isOpen.value = true;
        errorMessage.value = t('select.chooseOption');
        emit('change', '');
      } else {
        isOpen.value = true;
        errorMessage.value = '';
      }
    };
    const resetDisplayList = (): void => {
      displayOptionsArray.value = [...props.options as SelectOption[]];
    };
    const toggleList = (value?: boolean): void => {
      resetDisplayList();
      if (hasResults.value) {
        isOpen.value = value ?? (props.disabled ? false : !isOpen.value);
      }

      if (value === false) {
        inputValue.value = null;
      }
    };
    const inputChange = (data: InputEvent) => {
      const {
        displayProperty,
        options,
      } = props;
      const target = data.target as HTMLInputElement;
      let currentFieldValue = target.value;
      inputValue.value = currentFieldValue;
      currentFieldValue = currentFieldValue.toLowerCase();
      chosenOption.value = {};
      if (currentFieldValue.length === 0) {
        resetDisplayList();
        updateErrorMessage();
        return;
      }

      const tOptions = [...options] as SelectOption[];
      displayOptionsArray.value = tOptions.map((option: SelectOption) => {
        const optionString = option[displayProperty].toString()
          .toLowerCase();
        if (props.sortTypeIncludes) {
          if (optionString.includes(currentFieldValue)) {
            return { ...option, visible: true };
          }
        } else if (optionString.startsWith(currentFieldValue)) {
          return {
            ...option,
            visible: true,
          };
        }
        return { ...option, visible: false };
      });

      updateErrorMessage();
    };

    const getReturnValue = (option: SelectOption): SelectOption | SelectOption['k'] => {
      if (props.returnProperty === 'object') {
        return option;
      }
      return option[props.returnProperty];
    };

    const returnValue = (): void => {
      if (props.returnProperty === 'object') {
        emit('change', chosenOption.value);
        console.debug(`Returned ${chosenOption.value}`);
        return;
      }
      emit('change', chosenOption.value[props.returnProperty]);
      console.debug(`Returned ${chosenOption.value[props.returnProperty]}`);
    };
    const selectItem = (item: SelectOption): void => {
      inputValue.value = null;
      chosenOption.value = item;
      updateErrorMessage();
      toggleList(false);
      returnValue();
    };

    const onClickOutside = (e: MouseEvent) => {
      nextTick(() => {
        if (!el.value?.contains(e.target as HTMLElement) && isOpen.value) {
          updateErrorMessage();
          toggleList(false);
        }
      });
    };

    const getIcon = computed((): string => {
      const {
        iconAfter,
      } = props;
      if (iconAfter === 'chevron') {
        if (isOpen.value) return 'chevron-up';
        return 'chevron-down';
      }
      return '';
    });

    onMounted(() => {
      const {
        displayProperty,
        returnProperty,
        options,
        selectName,
      } = props;

      const tOptions: SelectOption[] = options as SelectOption[];

      if (tOptions.some((option) => !option[displayProperty])) {
        throw new Error(`No displayProperty:'${displayProperty}' in options array object`);
      }
      if (returnProperty !== 'object') {
        if (tOptions.some((option) => !option[returnProperty])) {
          throw new Error(`No returnProperty:'${returnProperty}' in options array object`);
        }
      }
      if (returnProperty === 'object') {
        console.warn(`Select with a name "${selectName}" requires separate hidden input`);
      }

      window.addEventListener('click', onClickOutside);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('click', onClickOutside);
    });
    watch(() => props.options, resetDisplayList);
    watch(() => props.defaultOption, () => {
      chosenOption.value = props.defaultOption ?? {};
    });

    return {
      el,
      isOpen,
      hasResults,
      chosenOption,
      errorMessage,
      displayOptionsArray,
      inputValue,
      toggleList,
      selectItem,
      getIcon,
      getReturnValue,
      inputChange,
    };
  },
});
</script>

<style lang="scss">
@import  '../scss/components/_select.scss';
</style>
