import { createApp } from 'vue/dist/vue.esm-bundler';
import VCalendar from 'v-calendar';
import * as Sentry from '@sentry/vue';
import i18n from '@/i18n';
import App from '@/App.vue';
import router from '@/router';
import { store, key } from '@/store';

// Vendor css
import '@/scss/index.scss';

const app = createApp(App);

// Add global variable
app.config.globalProperties.$matomoUrl = process.env.VUE_APP_MATOMO_CONTAINER;

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [process.env.VUE_APP_SENTRY_TRACE_PROPAGATION_TARGETS],

});

app
  .use(i18n)
  .use(router)
  .use(store, key)
  .use(VCalendar, {})
  .mount('#app');
