import { AxiosResponse } from 'axios';
// eslint-disable-next-line import/no-cycle
import api from '@/util/api';
import {
  CreateNewPartyRequestParams, IncidentPartyPatchResponseBody,
  IncidentPartyResponseBody,
  Party, ResponsibilityPatchRequestBody,
  ResponsibilityPostRequestBody,
  ResponsibilityResponse,
  IncidentMessageStatus,
} from '@/@types/incidentPartyService';
import {
  HandlingInsurerPatchResponse,
} from '@/@types/insuranceCompanyService';
import { InsurerPatchData } from '@/@types/insurer';

// TOKEN
export const getIncidentParty = async (partyKey: Party['key']): Promise<AxiosResponse<IncidentPartyResponseBody>> => api.get<IncidentPartyResponseBody>(`/incident/party/${partyKey}`);

export const patchIncidentPartyStatus = async (): Promise<AxiosResponse<IncidentPartyPatchResponseBody>> => api.patch<IncidentPartyPatchResponseBody>('/incident/party/status');

export const postIncidentPartyResponsibility = async (partyKey: Party['key'], postData: ResponsibilityPostRequestBody[]): Promise<AxiosResponse<ResponsibilityResponse[]>> => api.post<ResponsibilityResponse[]>(`/incident/party/${partyKey}/responsibility`, postData);

export const patchIncidentPartyResponsibility = async (partyKey: Party['key'], patchData: ResponsibilityPatchRequestBody[]): Promise<AxiosResponse<ResponsibilityResponse[]>> => api.patch<ResponsibilityResponse[]>(`/incident/party/${partyKey}/responsibility`, patchData);

export const patchIncidentPartyInsurer = async (partyKey : Party['key'], data: Omit<InsurerPatchData, 'partyKey'>): Promise<AxiosResponse<HandlingInsurerPatchResponse>> => api.patch(`/incident/party/${partyKey}/insurer`, data);

// PUBLIC
export const createIncidentParty = async (
  incidentKey: CreateNewPartyRequestParams['incidentKey'],
  isInitiator: CreateNewPartyRequestParams['isInitiator'],
  locale: CreateNewPartyRequestParams['locale'],
): Promise<AxiosResponse<IncidentPartyResponseBody>> => {
  let queryString = '';
  if (!isInitiator) {
    const urlSearchParams = new URLSearchParams({
      generateQR: '1',
      baseUrl: `${window.location.protocol}/${window.location.host}/`,
      urlPattern: `${locale}/[incidentKey]/[incidentPartyKey]`,
    });
    queryString = urlSearchParams.toString();
  }

  return api.post<IncidentPartyResponseBody>(`/public/incident/${incidentKey}/party?${queryString}`, { isInitiator });
};

export const getIncidentMessageStatus = async (): Promise<AxiosResponse<IncidentMessageStatus[]>> => api.get<IncidentMessageStatus[]>('/incident/message-status');
