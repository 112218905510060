import { AxiosResponse } from 'axios';
import api from '@/util/api';
import {
  CreateNewPersonRequestParams,
  IncidentPartyPersonResponseBody, Person,
} from '@/@types/incidentPartyPersonService';
import { Party } from '@/@types/incidentPartyService';

// TOKEN
export const getIncidentPartyPerson = async (partyKey: Party['key'], personId: Person['id']): Promise<AxiosResponse<IncidentPartyPersonResponseBody>> => api.get<IncidentPartyPersonResponseBody>(`/incident/party/${partyKey}/person/${personId}`);

export const postIncidentPartyPerson = async (partyKey: Party['key'], data: CreateNewPersonRequestParams): Promise<AxiosResponse<IncidentPartyPersonResponseBody>> => api.post<IncidentPartyPersonResponseBody>(`/incident/party/${partyKey}/person`, data);

export const patchIncidentPartyPerson = async (partyKey: Party['key'], personId: Person['id'], data: CreateNewPersonRequestParams): Promise<AxiosResponse<IncidentPartyPersonResponseBody>> => api.patch<IncidentPartyPersonResponseBody>(`/incident/party/${partyKey}/person/${personId}`, data);
