<template>
  <div
    class="view__app"
    :class="viewClasses"
  >
    <div class="snackbar__container max--width">
      <LKFSnackbar
        v-for="(snackbar) in snackbars"
        :key="snackbar.key"
        :message="snackbar.message"
        :type="snackbar.type"
        :button-text="snackbar.buttonText"
        :button-callback="snackbar.buttonCallback"
      />
    </div>

    <div
      class="loader__container"
      v-if="store.state.incident.loader.isLoading"
    >
      <div class="loader max--width--padded">
        <div class="lds-roller">
          <div /><div /><div /><div /><div /><div /><div /><div />
        </div>
        <div
          class="loader__message"
          v-if="store.state.incident.loader.loaderMessage"
        >
          {{ store.state.incident.loader.loaderMessage }}
        </div>
      </div>
    </div>
    <LKFCookieBar ref="cookies" />
    <section
      class="view__container max--width"
    >
      <LKFHeader />
      <main
        class="view"
      >
        <LKFPagination
          v-if="$route.meta.step as number > 0"
          :steps="12"
          :current-step="$route.meta.step as number"
          :force-pagination-visible="mustShowPagination"
        />
        <router-view
          :key="$route.path"
          @start-pinging-sms="startPingingSms"
        />
      </main>
      <LKFFooter />
    </section>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import {
  ref, watch, computed, onMounted, onUnmounted,
} from 'vue';
import Cookies from 'js-cookie';
import { useI18n } from 'vue-i18n';
import useSnackbar from '@/util/useHooks/useSnackbar';
import LKFHeader from '@/components/LKFHeader.vue';
import LKFFooter from '@/components/LKFFooter.vue';
import LKFSnackbar from '@/components/LKFSnackbar.vue';
import LKFPagination from '@/components/LKFPagination.vue';
import LKFCookieBar from '@/components/LKFCookiebar.vue';
import { useStore } from '@/store';
import { getIncidentMessageStatus } from '@/services/incidentParty';

const { snackbars } = useSnackbar();
const { t, locale } = useI18n();
const store = useStore();

const route = useRoute();
const setTitle = (): void => {
  let viewName = route.name?.toString() ?? 'fallback';
  if (viewName === 'IncidentToken') {
    viewName = 'CatchAll';
  }
  viewName = `${viewName.substring(0, 1).toLowerCase()}${viewName.substring(1)}`;
  document.title = `${t(`views.${viewName}.pageTitle`)} - LKF`;
};
const cookieValue = ref(localStorage.getItem('LKFLS-self-check') ?? '0');
const { addSnackbar } = useSnackbar();

const mustShowPagination = computed<boolean>(() => {
  const viewName = route.name?.toString() ?? 'fallback';
  if (viewName === 'Confirmation' || route.params.name === 'Confirmation') return true;
  return cookieValue.value === `${route.params.incidentKey}/${route.params.partyKey}`;
});

onMounted(() => {
  cookieValue.value = localStorage.getItem('LKFLS-self-check') ?? '0';
});

watch([locale, route], setTitle);
setTitle();

const viewClasses = ref<string[]>([]);
const setViewClasses = () => {
  viewClasses.value = [];
  if (route.name === 'CatchAll' || route.name === 'IncidentToken') {
    viewClasses.value.push('view__app--not-found');
  }
};

watch(() => route.name, setViewClasses);
setViewClasses();

const intervalId = ref<number>();
const SMS_NOTIFICATION_DISPLAYED_COOKIE = 'SMSloaded';
const pingSms = async () => {
  const cookieName = `${SMS_NOTIFICATION_DISPLAYED_COOKIE}_${route.params.incidentKey}_${route.params.partyKey}`;
  if (Cookies.get(cookieName) !== '1') {
    const { data } = await getIncidentMessageStatus();
    const successMessages: Set<string> = new Set();
    const failedMessages: Set<string> = new Set();
    let anyPending = false;

    data.forEach((value) => {
      if (value.partyKey === route.params.partyKey) {
        if (value.sentStatus === 'SENT') {
          successMessages.add(value.licencePlate);
        } else if (value.sentStatus === 'FAILED') {
          failedMessages.add(value.licencePlate);
        } else {
          anyPending = true;
        }
      }
    });

    if (anyPending) {
      return;
    }

    if (successMessages.size) {
      addSnackbar({
        type: 'success',
        message: t('views.conclusion.smsSentSuccessfully', { vehicleIdentifiers: [...successMessages].join(', ') }, successMessages.size),
        timeout: 5000,
      });
    }

    if (failedMessages.size) {
      addSnackbar({
        type: 'error',
        message: t('views.conclusion.error.failedToSendSms', { vehicleIdentifiers: [...failedMessages].join(', ') }, failedMessages.size),
        timeout: 5000,
      });
    }

    if (successMessages.size || failedMessages.size) {
      Cookies.set(cookieName, '1', { expires: 1 });
    }
    clearInterval(intervalId.value);
  }
};

const startPingingSms = () => {
  intervalId.value = setInterval(pingSms, 5000);
};

onUnmounted(() => {
  clearInterval(intervalId.value);
});

</script>
