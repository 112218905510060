import { AxiosResponse } from 'axios';
import api from '@/util/api';
import {
  IncidentCreateRequestBody,
  IncidentResponseBody,
  IncidentStatusPatchBody,
} from '@/@types/incidentService';

export const getIncident = async (): Promise<AxiosResponse<IncidentResponseBody>> => api.get<IncidentResponseBody>('/incident');

export const getIncidentSummary = async (ignoreLimit = false): Promise<AxiosResponse<IncidentResponseBody>> => api.get<IncidentResponseBody>(`/incident/summary?ignoreLimit=${ignoreLimit}`);

export const createIncident = async (data: IncidentCreateRequestBody): Promise<AxiosResponse<IncidentResponseBody>> => api.post<IncidentResponseBody>('/public/incident', {
  fillType: data.fillType,
  incidentType: data.incidentType,
  participantsCount: data.participantsCount,
});

export const patchIncidentStatus = async (data: IncidentStatusPatchBody): Promise<AxiosResponse<IncidentResponseBody>> => api.patch<IncidentResponseBody>('/incident/status', data);
