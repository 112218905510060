<template>
  <a :href="href">
    <slot />
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LKFLink',
  props: {
    href: {
      type: String,
      default: '#',
    },
  },
});
</script>
