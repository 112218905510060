/* eslint-disable import/no-cycle */
import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store } from 'vuex';
import Cookies from 'js-cookie';
import incidentModule from '@/store/modules/incident';
import vehiclesModule from '@/store/modules/vehicle';
import partyModule from '@/store/modules/party';
import eventModule from '@/store/modules/event';
import { PartyModule } from '@/@types/incidentPartyService';
import { VehiclesModule } from '@/@types/incidentPartyVehicleService';
import { IncidentModule } from '@/@types/incidentService';
import { EventModule } from '@/@types/incidentPartyEventService';
import { AuthRequestBody } from '@/@types/authService';
import { authenticateTokens } from '@/services/auth';
import { getIncident, getIncidentSummary } from '@/services/incident';

export interface State {
  events: EventModule;
  incident: IncidentModule;
  parties: PartyModule;
  vehicles: VehiclesModule;
}

export const key: InjectionKey<Store<State>> = Symbol('Store key');

export const store = createStore<State>({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    events: eventModule,
    incident: incidentModule,
    parties: partyModule,
    vehicles: vehiclesModule,
  },
  plugins: [],
  actions: {
    clearAllStates({ commit }) {
      commit('incident/clear');
      commit('parties/clear');
      commit('vehicles/clear');
      commit('events/clear');
    },
    async populateState(
      { commit, dispatch }, {
        authData,
        doSummary = false,
      }: { authData: AuthRequestBody, doSummary: boolean },
    ) {
      console.debug('Populating state for user: ', authData);

      Cookies.set('token', '');
      Cookies.set('refreshToken', '');
      await dispatch('clearAllStates');

      // Authenticate the user
      await authenticateTokens(authData);
      // Get the incident info
      const { data } = doSummary ? await getIncidentSummary(true) : await getIncident();

      if (data.parties.length === 0) {
        throw new Error('No parties found');
      }
      // Is valid incident that we can display to users

      // SET INCIDENT DATA
      commit('incident/setKey', data.key);
      commit('incident/setStatus', data.status);
      commit('incident/setParticipantsCount', data.participantsCount);
      commit('incident/setDbPartiesCount', data.numberOfIncidentParties);
      commit('incident/setType', data.incidentType);
      commit('incident/setFillType', data.fillType);
      commit('incident/setCreatedAt', data.createdAt);

      // SET PARTIES
      await Promise.all(
        data.parties.sort((a, b) => a.id - b.id).map(
          (party) => dispatch('parties/populateParty', { incidentKey: data.key, partyData: party }, { root: true }),
        ),
      );

      const partyToGetResponsibilityFrom = data.parties.find(
        ({ key: findKey }) => findKey === authData.incidentPartyIdentifier,
      );
      if (partyToGetResponsibilityFrom) {
        // Populate the party responsibility data if possible
        if (partyToGetResponsibilityFrom.responsibilities.length) {
          const withIncidentPartyKeyMapped = partyToGetResponsibilityFrom.responsibilities
            .map((responsibilityResponse) => {
              const copy = { ...responsibilityResponse };
              if (!copy.incidentPartyKey) copy.incidentPartyKey = partyToGetResponsibilityFrom.key;
              return copy;
            });

          withIncidentPartyKeyMapped.forEach(
            (responsibility) => {
              dispatch('parties/setPartyResponsibility', responsibility);
            },
          );
          dispatch('parties/setPartyOtherResponsibilities', {
            partyKey: partyToGetResponsibilityFrom.key,
            responsibilities: withIncidentPartyKeyMapped,
          });
        }
      }
    },
  },
});

export function useStore(): Store<State> {
  return baseUseStore(key);
}
