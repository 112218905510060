import { Module } from 'vuex';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios';
// eslint-disable-next-line import/no-cycle
import { State } from '@/store';
// eslint-disable-next-line import/no-cycle
import {
  createIncidentPartyVehicle,
  getIncidentPartyVehicleImages,
  patchIncidentPartyVehicleRegNr,
  putIncidentPartyForeignVehicle,
  putIncidentPartyUnregisteredVehicle,
} from '@/services/incidentPartyVehicle';
import {
  ForeignVehicleUpdateData,
  IncidentPartyVehicleCreateResponseBody,
  IncidentPartyVehiclePutResponseBody,
  regNrUpdateData,
  UnregisteredVehicleUpdateData,
  Vehicle,
  VehiclesModule,
} from '@/@types/incidentPartyVehicleService';
import { Party } from '@/@types/incidentPartyService';
import useSnackbar from '@/util/useHooks/useSnackbar';
import i18n from '@/i18n';
import { ApiImage, ApiImageDeleteRequest } from '@/@types/incidentPartyEventService';
// eslint-disable-next-line import/no-cycle
import { remove } from '@/services/uploadService';

const vehicleModule: Module<VehiclesModule, State> = ({
  namespaced: true,
  state: () => ({ vehicles: [] }),
  mutations: {
    createNewVehicle(state, vehicleData: IncidentPartyVehicleCreateResponseBody) {
      const cleanedData: Vehicle = {
        vehicleId: vehicleData.id,
        partyKey: vehicleData.partyKey,
        make: '',
        model: '',
        otherType: '',
        registrationNr: '',
        isForeign: false,
        isProperty: false,
        hasNoRegNr: false,
        images: [],
        isUnableToAddPhotos: false,
        insurers: [],
        status: 'CURRENT',
      };
      state.vehicles.push(cleanedData);
    },
    updateVehicleByPartyKey(
      state,
      {
        partyKey,
        vehicleData,
      }: { partyKey: Party['key'], vehicleData: IncidentPartyVehiclePutResponseBody },
    ) {
      const vehicleIndex = state.vehicles.findIndex((vehicle) => vehicle.partyKey === partyKey);
      if (vehicleIndex !== -1) {
        state.vehicles[vehicleIndex] = {
          ...state.vehicles[vehicleIndex],
          partyKey,
          vehicleId: vehicleData.id,
          make: vehicleData.make,
          model: vehicleData.model,
          registrationNr: vehicleData.registrationNr,
          insurers: vehicleData.insurers,
          otherType: vehicleData.propertyName,
          isUnableToAddPhotos: vehicleData.isUnableToAddPhotos === 1,
          hasNoRegNr: vehicleData.registrationNr === '',
          isProperty: vehicleData.vehicleType === 'PROPERTY',
          isForeign: vehicleData.insurers.some(
            ({ country: { shortCode } }) => shortCode !== 'EE',
          ),
        };
      }
    },
    populateVehicleByVehicleIndex(state, vehicleData: Vehicle) {
      const vehicleIndex = state.vehicles.findIndex(
        (vehicle) => vehicle.partyKey === vehicleData.partyKey,
      );
      if (vehicleIndex !== -1) {
        const copy = [...state.vehicles];
        copy[vehicleIndex] = vehicleData;
        state.vehicles = copy;
      } else {
        state.vehicles.push(vehicleData);
      }
    },
    clearVehicleRegistryData(state, vehicleId: Vehicle['vehicleId']) {
      const vehicleIndex = state.vehicles.findIndex((vehicle) => vehicle.vehicleId === vehicleId);
      if (vehicleIndex !== -1) {
        state.vehicles[vehicleIndex] = {
          ...state.vehicles[vehicleIndex],
          make: '',
          model: '',
          registrationNr: '',
          otherType: '',
          insurers: [],
        };
      }
    },
    setForeignVehicleByKey(
      state,
      { partyKey, regNr, isUnableToAddPhotos }:
        { partyKey: Party['key'], regNr: Vehicle['registrationNr'], isUnableToAddPhotos: Vehicle['isUnableToAddPhotos'] },
    ) {
      const vehicleIndex = state.vehicles.findIndex((vehicle) => vehicle.partyKey === partyKey);
      if (vehicleIndex !== -1) {
        state.vehicles[vehicleIndex].isForeign = true;
        state.vehicles[vehicleIndex].hasNoRegNr = false; // always has to have reg nr
        state.vehicles[vehicleIndex].registrationNr = regNr;
        state.vehicles[vehicleIndex].isUnableToAddPhotos = isUnableToAddPhotos;
      }
    },
    setNoRegNrVehicleById(
      state,
      vehicleId: Vehicle['vehicleId'],
    ) {
      const vehicleIndex = state.vehicles.findIndex((vehicle) => vehicle.vehicleId === vehicleId);
      if (vehicleIndex !== -1) {
        state.vehicles[vehicleIndex].hasNoRegNr = true;
      }
    },
    setIsPropertyByKey(state, vehicleId: Vehicle['vehicleId']) {
      const vehicleIndex = state.vehicles.findIndex((vehicle) => vehicle.vehicleId === vehicleId);
      if (vehicleIndex !== -1) {
        state.vehicles[vehicleIndex].isProperty = true;
      }
    },
    addPictureToStore(state, { partyKey, ...image }: { partyKey: Party['key'] } & ApiImage) {
      const vehicleIndex = state.vehicles.findIndex((vehicle) => vehicle.partyKey === partyKey);
      if (vehicleIndex !== -1) {
        state.vehicles[vehicleIndex].images.push(image);
        return;
      }
      console.error('Could not add picture to Store - vehicle not found');
    },
    removePictureFromStore(state, {
      partyKey,
      index,
    }: { partyKey: Party['key'], index: ApiImage['index'] }) {
      const vehicleIndex = state.vehicles.findIndex((vehicle) => vehicle.partyKey === partyKey);
      if (vehicleIndex !== -1) {
        state.vehicles[vehicleIndex].images.splice(
          index, 1,
        );
        return;
      }
      console.error('Could not remove picture from Store - vehicle not found');
    },
    clear(state) {
      state.vehicles = [];
    },
  },
  actions: {
    async maybeCreateNewVehicle(
      { commit, getters },
      partyKey: Party['key'],
    ): Promise<Vehicle['vehicleId']> {
      const updatingVehicle: Vehicle = { ...getters.getByPartyKey(partyKey) };
      if (updatingVehicle.status === 'PENDING') {
        const { data } = await createIncidentPartyVehicle(partyKey);

        updatingVehicle.vehicleId = data.id;
        updatingVehicle.status = data.status;
        updatingVehicle.images = [];

        commit('populateVehicleByVehicleIndex', updatingVehicle);
      }
      return updatingVehicle.vehicleId;
    },
    async createNewVehicle({ commit }, partyKey: Party['key']) {
      try {
        const { data } = await createIncidentPartyVehicle(partyKey);

        data.partyKey = partyKey;

        commit('createNewVehicle', data);
        return true;
      } catch (e) {
        console.error(e);

        const { addSnackbar } = useSnackbar();
        const { tm } = i18n.global;
        addSnackbar({
          message: tm('requestErrors.vehicle.unableToCreateApiVehicle'),
          type: 'error',
        });
        return false;
      }
    },
    async populateVehicle({ commit }, {
      partyKey,
      currentVehicle,
    }: { partyKey: Party['key'], currentVehicle: IncidentPartyVehiclePutResponseBody }) {
      const vehicle: Vehicle = {
        partyKey,
        vehicleId: currentVehicle.id,
        make: currentVehicle.make,
        model: currentVehicle.model,
        registrationNr: currentVehicle.registrationNr,
        insurers: currentVehicle.insurers,
        otherType: currentVehicle.propertyName,
        isUnableToAddPhotos: currentVehicle.isUnableToAddPhotos === 1,
        hasNoRegNr: (
          !currentVehicle.registrationNr && currentVehicle.propertyName !== 'PROPERTY'
        ) || (
          currentVehicle.vehicleType === 'PROPERTY' && currentVehicle.propertyName === 'PROPERTY'
        ),
        isProperty: currentVehicle.vehicleType === 'PROPERTY' && currentVehicle.propertyName === 'PROPERTY',
        isForeign: currentVehicle.insurers.some(
          ({ country: { shortCode } }) => shortCode !== 'EE',
        ),
        images: [],
        status: currentVehicle.status,
      };

      const { data: apiImages } = await getIncidentPartyVehicleImages(partyKey, currentVehicle.id);

      vehicle.images = apiImages.map<ApiImage>(
        ({ imageThumbBase64, id }, index) => ({ base64: imageThumbBase64, index, id }),
      );

      commit('populateVehicleByVehicleIndex', vehicle);
    },
    async patchRegNr(
      { commit, dispatch },
      {
        partyKey, regNr, isUnableToAddPhotos, eventDateTime,
      }: regNrUpdateData,
    ) {
      try {
        const vehicleId = await dispatch('maybeCreateNewVehicle', partyKey);

        const { data } = await patchIncidentPartyVehicleRegNr(
          partyKey, vehicleId, regNr, isUnableToAddPhotos, eventDateTime,
        );
        commit('updateVehicleByPartyKey', { partyKey, vehicleData: data });
        return true;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TS1196
      } catch (e: AxiosError) {
        const { addSnackbar } = useSnackbar();
        const { tm } = i18n.global;

        if (e.response?.status === 409) {
          addSnackbar({
            message: tm('requestErrors.vehicle.unableToPatchApiVehicleRegNrConflict'),
            type: 'error',
          });
          return false;
        }

        if (e.response?.status === 404) {
          commit('setForeignVehicleByKey', { partyKey, regNr, isUnableToAddPhotos });
          return 'FOREIGN';
        }
        console.error(e);

        addSnackbar({
          message: tm('requestErrors.vehicle.unableToPatchApiVehicleRegNr'),
          type: 'error',
        });
        return false;
      }
    },
    async putForeignVehicleInfo({ commit, dispatch }, updateData: ForeignVehicleUpdateData) {
      try {
        const copy = { ...updateData };
        copy.vehicleId = await dispatch('maybeCreateNewVehicle', updateData.partyKey);
        const { data } = await putIncidentPartyForeignVehicle(copy);
        commit('updateVehicleByPartyKey', { partyKey: copy.partyKey, vehicleData: data });
        return true;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      } catch (e: AxiosError) {
        const { addSnackbar } = useSnackbar();
        const { tm } = i18n.global;
        console.error(e);

        addSnackbar({
          message: tm('requestErrors.vehicle.unableToPutForeignVehicleData'),
          type: 'error',
        });
        return false;
      }
    },
    async putUnregisteredVehicleInfo(
      { commit, dispatch }, updateData: UnregisteredVehicleUpdateData,
    ) {
      try {
        const copy = { ...updateData };
        copy.vehicleId = await dispatch('maybeCreateNewVehicle', updateData.partyKey);
        const { data } = await putIncidentPartyUnregisteredVehicle(copy);
        data.vehicleType = 'MOTOR_VEHICLE';
        commit('updateVehicleByPartyKey', { partyKey: copy.partyKey, vehicleData: data });
        commit('setNoRegNrVehicleById', updateData.vehicleId);
        return true;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      } catch (e: AxiosError) {
        const { addSnackbar } = useSnackbar();
        const { tm } = i18n.global;
        console.error(e);

        addSnackbar({
          message: tm('requestErrors.vehicle.unableToPutUnregisteredVehicleOrPropertyData'),
          type: 'error',
        });
        return false;
      }
    },
    async putPropertyInfo({ commit, dispatch }, updateData: UnregisteredVehicleUpdateData) {
      try {
        const copy = { ...updateData };
        copy.vehicleId = await dispatch('maybeCreateNewVehicle', updateData.partyKey);

        const { data } = await putIncidentPartyUnregisteredVehicle(copy);
        commit('updateVehicleByPartyKey', { partyKey: copy.partyKey, vehicleData: data });
        commit('setNoRegNrVehicleById', updateData.vehicleId);
        commit('setIsPropertyByKey', updateData.vehicleId);
        return true;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      } catch (e: AxiosError) {
        const { addSnackbar } = useSnackbar();
        const { tm } = i18n.global;
        console.error(e);

        addSnackbar({
          message: tm('requestErrors.vehicle.unableToPutUnregisteredVehicleOrPropertyData'),
          type: 'error',
        });
        return false;
      }
    },
    async removePictureFromStore({ state, commit }, {
      partyKey,
      imageIndex,
    }: { partyKey: Party['key'] } & ApiImageDeleteRequest) {
      const vehicle = state.vehicles.find((lVehicle) => lVehicle.partyKey === partyKey);
      if (!vehicle) {
        return false;
      }
      const image = vehicle.images.find(({ index }) => index === imageIndex);
      if (!image) {
        return false;
      }

      const success = await remove(partyKey, image.id);
      commit('removePictureFromStore', { partyKey, index: imageIndex });
      return success;
    },
  },
  getters: {
    all(state) {
      return state.vehicles;
    },
    getByPartyKey: (state) => (key: Party['key']): Vehicle | undefined => state.vehicles.find((vehicle) => vehicle.partyKey === key),
    getUploadedImagesByPartyKey: (state) => (key: Party['key']) => {
      const obj = state.vehicles.find((vehicle) => vehicle.partyKey === key);
      if (obj !== undefined) return obj.images;
      return null;
    },
  },
});

export default vehicleModule;
