<template>
  <span
    v-if="iconType === 'svg'"
    class="icon"
    v-html="iconSVG"
  />
  <span
    v-else-if="iconType === 'flag' && iconSrc"
    class="icon icon--flag"
    :class="iconSrc"
  />
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import feather, { FeatherIconNames } from 'feather-icons';

export default defineComponent({
  name: 'Icon',
  props: {
    iconName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const iconSVG = ref('');
    const iconSrc = ref<string[]>([]);
    const iconType = ref('svg');
    const iconNameUpdate = () => {
      if (props.iconName) {
        if (props.iconName.startsWith('flag-')) {
          const flagName = props.iconName.replace('flag-', '').toUpperCase();

          iconSrc.value = [`flag:${flagName}`];
          iconType.value = 'flag';
        } else {
          iconType.value = 'svg';
          iconSVG.value = (props.iconName ? feather.icons[props.iconName as FeatherIconNames]?.toSvg() ?? '' : '');
        }
      }
    };
    watch(() => props.iconName, iconNameUpdate);
    iconNameUpdate();
    return {
      iconSVG,
      iconSrc,
      iconType,
    };
  },
});
</script>
