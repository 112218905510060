import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled", "value", "type"]
const _hoisted_2 = { class: "lkf-button__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(['lkf-button', ..._ctx.btnClassList]),
    disabled: _ctx.disabled,
    value: _ctx.value,
    type: _ctx.type
  }, [
    (_ctx.iconName)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          "icon-name": _ctx.iconName,
          class: "lkf-button__icon"
        }, null, 8, ["icon-name"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 10, _hoisted_1))
}