<template>
  <LKFModal
    style="pointer-events:auto"
    :is-open="isOpen"
    :title="t('cookie-bar.data-protection-title')"
  >
    <p style="white-space: pre-wrap;">
      {{ t('cookie-bar.data-protection-content') }}
    </p>
  </LKFModal>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import { useI18n } from 'vue-i18n';
import LKFModal from '@/components/LKFModal.vue';

const { t } = useI18n();
defineProps<{ isOpen?: boolean }>();
</script>
