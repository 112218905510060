/* eslint-disable import/no-cycle */
import { Module } from 'vuex';
import { State } from '@/store';
import { createIncident, patchIncidentStatus } from '@/services/incident';
import { IncidentModule, IncidentStatusPatchBody } from '@/@types/incidentService';
import useSnackbar from '@/util/useHooks/useSnackbar';
import i18n from '@/i18n';
import useBodyOverflow from '@/util/useHooks/useBodyOverflow';

const baseState: IncidentModule = {
  key: null,
  participantsCount: 2,
  dbPartiesCount: 0,
  incidentType: null,
  fillType: null,
  createdAt: Date.now().toString(),
  status: 'DRAFT',
  loader: {
    isLoading: true,
    loaderMessage: '',
  },
};
// Create copy just in case of some memory reference issues
const defaultState = {
  ...baseState,
};
const incidentModule: Module<IncidentModule, State> = ({
  namespaced: true,
  state: () => baseState,
  mutations: {
    setKey(state, incidentKey: IncidentModule['key']) {
      state.key = incidentKey;
    },
    setParticipantsCount(state, newCount: IncidentModule['participantsCount']) {
      state.participantsCount = newCount;
    },
    setDbPartiesCount(state, newCount: IncidentModule['dbPartiesCount']) {
      state.dbPartiesCount = newCount;
    },
    setType(state, incidentType: IncidentModule['incidentType']) {
      state.incidentType = incidentType;
    },
    setFillType(state, fillType: IncidentModule['fillType']) {
      state.fillType = fillType;
    },
    setCreatedAt(state, createdAt: IncidentModule['createdAt']) {
      state.createdAt = createdAt;
    },
    setStatus(state, status: IncidentModule['status']) {
      state.status = status;
    },
    setLoading(state, loadingState: boolean) {
      const { hideOverflow, showOverflow } = useBodyOverflow();
      if (loadingState) {
        hideOverflow();
      } else {
        showOverflow();
      }
      state.loader.isLoading = loadingState;
      state.loader.loaderMessage = '';
    },
    setLoadingMessage(state, message: string) {
      state.loader.loaderMessage = message;
    },
    clear(state) {
      Object.assign(state, defaultState);
    },
  },
  actions: {
    async createNewIncident({ state, commit }): Promise<string | null> {
      try {
        const { data } = await createIncident(
          {
            fillType: state.fillType,
            incidentType: state.incidentType,
            participantsCount: state.participantsCount,
          },
        );
        commit('setKey', data.key);
        commit('setCreatedAt', data.createdAt);
        return data.key;
      } catch (e) {
        console.error(e);

        const { addSnackbar } = useSnackbar();
        const { tm } = i18n.global;
        addSnackbar({
          message: tm('requestErrors.incident.unableToGetApiIncident'),
          type: 'error',
        });
        return null;
      }
    },
    async patchStatus({ commit }, newStatus: IncidentStatusPatchBody['status']): Promise<boolean> {
      try {
        await patchIncidentStatus({ status: newStatus });
        commit('setStatus', newStatus);
        return true;
      } catch (e) {
        console.error(e);

        const { addSnackbar } = useSnackbar();
        const { tm } = i18n.global;
        addSnackbar({
          message: tm('requestErrors.incident.unableToPatchIncidentStatus'),
          type: 'error',
        });
        return false;
      }
    },
  },
  getters: {
    key: (state) => state.key,
    state: (state) => state,
  },
});
export default incidentModule;
