<template>
  <section
    class="pagination"
    v-if="!canShowPagination"
  >
    <div class="pagination__circle pagination__circle--active">
      <Icon
        icon-name="check"
        v-if="currentStep !== 1"
        class="pagination__icon"
      />
    </div>
    <div
      :class="firstLineClasses"
      class="pagination__divider"
    />
    <div
      :class="secondCircleClasses"
      class="pagination__circle"
    >
      <span>12</span>
    </div>
    <div
      class="pagination__divider"
      :class="secondLineClasses"
    />
    <div
      class="pagination__circle"
      :class="thirdCircleClasses"
    >
      {{ steps }}
    </div>
  </section>

  <section
    class="pagination"
    v-if="canShowPagination"
  >
    <div class="pagination__circle pagination__circle--active">
      <Icon
        icon-name="check"
        v-if="currentStep !== 1"
        class="pagination__icon"
      />
      <span v-else>{{ currentStep }}</span>
    </div>
    <div
      :class="firstLineClasses"
      class="pagination__divider"
    />
    <div
      :class="secondCircleClasses"
      class="pagination__circle"
    >
      <span v-if="currentStep === 1">2</span>
      <span v-else>{{ currentStep }}</span>
    </div>
    <div
      class="pagination__divider"
      :class="secondLineClasses"
    />
    <div
      class="pagination__circle"
      :class="thirdCircleClasses"
    >
      {{ steps }}
    </div>
  </section>
</template>

<script lang="ts">
import {
  computed, defineComponent, ref, watch,
} from 'vue';
import { useRoute } from 'vue-router';
import Icon from '@/components/Icon.vue';
import { useStore } from '@/store';
import { Party } from '@/@types/incidentPartyService';

export default defineComponent({
  name: 'LKFPagination',
  components: { Icon },
  props: {
    steps: {
      type: Number,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    forcePaginationVisible: {
      type: Boolean,
    },
  },

  setup(props) {
    if (props.currentStep === 0 || props.currentStep > props.steps) {
      throw new Error('Pagination received incorrect currentStep value!');
    }

    const secondCircleClasses = ref<string[]>([]);
    const firstLineClasses = ref<string[]>([]);
    const secondLineClasses = ref<string[]>([]);
    const thirdCircleClasses = ref<string[]>([]);
    const store = useStore();
    const { params: { partyKey }, name } = useRoute();

    const canShowPagination = computed(() => {
      if (props.forcePaginationVisible) return true;
      const isIncidentEditable = (store.state.incident.status === 'PENDING' || store.state.incident.status === 'DRAFT');
      if (name === 'Index') return true;
      const party: Party | undefined = store.getters['parties/getParty'](partyKey);
      if (party) return (party.status === 'PENDING' || party.status === 'DRAFT') && isIncidentEditable;
      return isIncidentEditable;
    });

    const calcClasses = () => {
      secondCircleClasses.value = [];
      firstLineClasses.value = [];
      secondLineClasses.value = [];
      thirdCircleClasses.value = [];
      if (props.currentStep > 1 && props.currentStep <= props.steps) {
        firstLineClasses.value.push('pagination__divider--active');
        secondCircleClasses.value.push('pagination__circle--active');
      }
      if (props.currentStep === props.steps) {
        secondLineClasses.value.push('pagination--hidden');
        thirdCircleClasses.value.push('pagination--hidden');
      }
      if (canShowPagination.value === false) {
        secondLineClasses.value.push('pagination--hidden');
        thirdCircleClasses.value.push('pagination--hidden');
      }
    };
    watch(() => props.currentStep, calcClasses);
    calcClasses();

    return {
      firstLineClasses,
      secondCircleClasses,
      secondLineClasses,
      thirdCircleClasses,
      canShowPagination,
    };
  },
});
</script>
