<template>
  <div class="footer">
    <hr class="divider divider--green">
    <div class="footer__bottom">
      <b class="full--width footer__bottom_text">{{ t('footer.lkfLong') }}</b>
      <LKFLink
        class="footer__bottom_link"
        href="tel:+3726671820"
      >
        <Icon
          class="footer__bottom_icon"
          icon-name="phone"
        />
        667 1820
      </LKFLink>
      <LKFLink
        class="footer__bottom_link"
        href="mailto:lkf@lkf.ee"
      >
        <Icon
          class="footer__bottom_icon"
          icon-name="mail"
        />
        lkf@lkf.ee
      </LKFLink>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import LKFLink from '@/components/LKFLink.vue';
import Icon from '@/components/Icon.vue';

export default defineComponent({
  name: 'LKFFooter',
  components: {
    Icon,
    LKFLink,
  },
  setup() {
    const { t } = useI18n();
    return { t };
  },
});
</script>
