import { ObjStatuses } from '@/@types/incidentPartyService';

export default function getCurrentOrPendingObj<T extends { status: ObjStatuses }>(
  arr: T[],
): T | false {
  const foundCurrent = arr.find(
    ({ status }) => status === 'CURRENT',
  );
  if (!foundCurrent) {
    const foundPending = arr.find(
      ({ status }) => status === 'PENDING',
    );
    if (!foundPending) return false;
    return foundPending;
  }
  return foundCurrent;
}
