interface Validators {
  uid: string,
  callback: () => boolean
  clear: () => void
}
interface UseValidateForm {
  validate(): boolean
  clearErrors(): void
  addValidator(uid: Validators['uid'], callback: Validators['callback'], clear: Validators['clear']): void
  removeValidator(uid: Validators['uid']): void
}

const validators: Validators[] = [];

const validate: UseValidateForm['validate'] = () => {
  let isValid = true;
  validators.forEach((validator) => {
    isValid = validator.callback() && isValid;
  });
  return isValid;
};
const clearErrors: UseValidateForm['clearErrors'] = () => {
  validators.forEach((validator) => {
    validator.clear();
  });
};

const addValidator: UseValidateForm['addValidator'] = (uid, callback, clear) => {
  if (validators.find((validator) => validator.uid === uid)) {
    console.debug('Validator found match when adding new. Some component is having issues', uid);
  }
  validators.push({ uid, callback, clear });
};

const removeValidator: UseValidateForm['removeValidator'] = (uid) => {
  const validatorIndex = validators.findIndex((validator) => validator.uid === uid);
  if (validatorIndex === -1) {
    console.debug('Validator didn\'t find a match when trying to remove validator. Some component is having issues', uid);
  } else {
    validators.splice(validatorIndex, 1);
  }
};
export default function useValidateForm():UseValidateForm {
  return {
    validate,
    clearErrors,
    addValidator,
    removeValidator,
  };
}
