import Cookies from 'js-cookie';
import { AxiosResponse } from 'axios';
// eslint-disable-next-line import/no-cycle
import api from '@/util/api';
import { AuthRefreshRequestBody, AuthRequestBody, AuthResponseBody } from '@/@types/authService';
import useSnackbar from '@/util/useHooks/useSnackbar';
import i18n from '@/i18n';

/**
 * REQUEST HANDLERS
 */

export const authenticateTokens = async (authData: AuthRequestBody): Promise<void> => {
  try {
    console.debug('Authenticating data: ', authData);
    const resp = await api.post<AuthResponseBody>('/auth/incident', authData);
    Cookies.set('token', resp.data.access_token);
    Cookies.set('refreshToken', resp.data.refresh_token);
    return Promise.resolve();
  } catch (e) {
    const { addSnackbar } = useSnackbar();
    const { tm } = i18n.global;
    addSnackbar({
      message: tm('requestErrors.incident.unableToAuthenticateUser'),
      type: 'error',
    });
    return Promise.reject(e);
  }
};

export const refreshAuthentication = async ():
  Promise<AxiosResponse<AuthResponseBody>> => {
  // Do the token refresh request
  const refreshToken = Cookies.get('refreshToken');

  if (!refreshToken) {
    return Promise.reject(new Error('No token to refresh'));
  }

  const data: AuthRefreshRequestBody = {
    grant_type: 'REFRESH',
    refresh_token: refreshToken,

  };
  return api.post<AuthResponseBody>('/auth/incident/renewal', data);
};

export default { authenticateTokens, refreshAuthentication };
