<template>
  <div
    v-if="isModalDisplayed"
    class="lkf-modal--background"
    ref="modal"
    @click="modalBgClose"
  >
    <div
      :class="{'lkf-modal--header-hidden': (!title && !isCloseable)}"
      class="lkf-modal max--width"
    >
      <div
        :class="{'lkf-modal__header--no-title': (!title && isCloseable)}"
        class="lkf-modal__header"
      >
        <h2 class="lkf-modal__header_title">
          {{ title }}
        </h2>
        <LKFButton
          v-show="isCloseable"
          :class="{'lkf-modal__header_button--big' : title && isCloseable}"
          class="lkf-modal__header_button"
          icon-name="x"
          subtle-icon-btn
          variant="icon"
          @click="closeModal"
        />
      </div>
      <div class="lkf-modal__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, ref, watch,
} from 'vue';
import useBodyOverflow from '@/util/useHooks/useBodyOverflow';
import LKFButton from '@/components/LKFButton.vue';

export type LKFModalType = {
  openModal(): void,
  closeModal(): void
}
export default defineComponent({
  name: 'LKFModal',
  components: {
    LKFButton,
  },
  props: {
    isOpen: {
      type: Boolean,
    },
    isCloseable: {
      type: Boolean,
      default: true,
    },
    title: { type: String, default: '' },
  },
  emits: ['modalClose', 'modalBgClose'],
  setup(props, { emit }) {
    const {
      hideOverflow,
      showOverflow,
    } = useBodyOverflow();

    const modal = ref<HTMLElement|null>(null);

    const isModalDisplayed = ref<boolean>(props.isOpen);
    const openModal = () => {
      isModalDisplayed.value = true;
      hideOverflow();
    };

    const closeModal = () => {
      emit('modalClose');
      isModalDisplayed.value = false;
      showOverflow();
    };

    const modalBgClose = (e: MouseEvent) => {
      if (modal.value && e.target && modal.value === e.target as HTMLElement) {
        emit('modalBgClose');
      }
    };

    watch(() => props.isOpen, () => {
      if (props.isOpen) {
        openModal();
      } else {
        closeModal();
      }
    });

    return {
      modal,
      isModalDisplayed,
      openModal,
      closeModal,
      modalBgClose,
    };
  },
});
</script>
