/*  eslint-disable import/no-cycle */
import { Module } from 'vuex';
import { State } from '@/store';
import {
  IncidentPartyEvent,
  EventModule,
  IncidentPartyCreateEventResponseBody,
  IncidentPartyEventPatchResponseBody,
  ApiImage,
  IncidentPartyEventPatchRequestBody,
  ApiImageDeleteRequest,
  IncidentPartyEventGetResponseBody,
} from '@/@types/incidentPartyEventService';
import {
  createIncidentPartyEvent, getIncidentPartyEventFiles,
  patchIncidentPartyEvent,
} from '@/services/incidentPartyEvent';
import { Party } from '@/@types/incidentPartyService';
import useSnackbar from '@/util/useHooks/useSnackbar';
import i18n from '@/i18n';
import { remove } from '@/services/uploadService';

const eventModule: Module<EventModule, State> = ({
  namespaced: true,
  state: () => ({ events: [] }),
  mutations: {
    createNewEvent(state, { partyKey, data }:{ partyKey: Party['key'], data: IncidentPartyCreateEventResponseBody }) {
      const cleanedData: IncidentPartyEvent = {
        partyKey,
        id: data.id,
        images: [],
        isUnableToAddPhotos: false,
        eventDateTime: '',
        eventDescription: null,
        eventDescriptionDetails: '',
        customDescCheckBoxValue: true,
        uploadedAudio: false,
        address: '',
        xCoordinate: 0,
        yCoordinate: 0,
        ehakCode: 0,
        ehakKovCode: 0,
        ehakMkCode: 0,
        locationDescription: '',
        createdAt: data.createdAt,
        status: data.status,
      };
      state.events.push(cleanedData);
    },
    updateEvent(state, { partyKey, data }: { partyKey: Party['key'], data: IncidentPartyEventPatchResponseBody}) {
      const eventIndex = state.events.findIndex((event) => event.partyKey === partyKey);
      if (eventIndex !== -1) {
        state.events[eventIndex] = {
          ...state.events[eventIndex],
          ...data,
          isUnableToAddPhotos: data.isUnableToAddPhotos === 1,
        };
      }
    },
    populateEventByEventIndex(state, eventData: IncidentPartyEvent) {
      const eventIndex = state.events.findIndex(
        (event) => event.partyKey === eventData.partyKey,
      );
      if (eventIndex !== -1) {
        state.events[eventIndex] = eventData;
      } else {
        state.events.push(eventData);
      }
    },
    addPictureToStore(state, {
      partyKey, ...image
    }:{partyKey: Party['key']} & ApiImage) {
      const eventIndex = state.events.findIndex((event) => event.partyKey === partyKey);
      if (eventIndex !== -1) {
        state.events[eventIndex].images.push(image);
        return;
      }
      console.error('Could not add picture to Store - event not found');
    },
    removePictureFromStore(state, { partyKey, index }:{partyKey: Party['key'], index: ApiImage['index']}) {
      const eventIndex = state.events.findIndex((event) => event.partyKey === partyKey);
      if (eventIndex !== -1) {
        state.events[eventIndex].images = state.events[eventIndex].images.filter(
          (image) => image.index !== index,
        );
        return;
      }
      console.error('Could not remove picture from Store - event not found');
    },
    setCustomDescCheckBoxValue(state, { partyKey, value }: {partyKey: Party['key'], value: boolean }) {
      const eventIndex = state.events.findIndex((event) => event.partyKey === partyKey);
      if (eventIndex !== 1) {
        state.events[eventIndex].customDescCheckBoxValue = value;
        return;
      }
      console.error('Could not set ta value to custom checkbox in the store - event not found');
    },

    setHasUploadedAudio(state, { partyKey, value }: {partyKey: Party['key'], value: IncidentPartyEvent['uploadedAudio'] }) {
      const eventIndex = state.events.findIndex((event) => event.partyKey === partyKey);
      if (eventIndex !== 1) {
        state.events[eventIndex].uploadedAudio = value;
        return;
      }
      console.error('Could set the value "uploadedAudio" in the store - event not found');
    },
    setAddress(state, { partyKey, address }: {partyKey: Party['key'], address: {
        address: IncidentPartyEvent['address'],
        xCoordinate: IncidentPartyEvent['xCoordinate'],
        yCoordinate: IncidentPartyEvent['yCoordinate'],
        ehakCode: IncidentPartyEvent['ehakCode'],
        ehakKovCode: IncidentPartyEvent['ehakKovCode'],
        ehakMkCode: IncidentPartyEvent['ehakMkCode'],
      } }) {
      const eventIndex = state.events.findIndex((event) => event.partyKey === partyKey);
      if (eventIndex !== 1) {
        state.events[eventIndex].address = address.address;
        state.events[eventIndex].xCoordinate = address.xCoordinate;
        state.events[eventIndex].yCoordinate = address.yCoordinate;
        state.events[eventIndex].ehakCode = address.ehakCode;
        state.events[eventIndex].ehakKovCode = address.ehakKovCode;
        state.events[eventIndex].ehakMkCode = address.ehakMkCode;
        return;
      }
      console.error('Could set address in the store - event not found');
    },
    clear(state) {
      state.events = [];
    },
  },
  actions: {
    async maybeCreateNewEvent({ getters, commit }, partyKey: Party['key']) {
      const storeEvent: IncidentPartyEvent = getters.getByPartyKey(partyKey);
      if (storeEvent.status === 'PENDING') {
        const { data: newEventData } = await createIncidentPartyEvent(partyKey);
        const copy: IncidentPartyEvent = {
          ...storeEvent,
          id: newEventData.id,
          status: newEventData.status,
          createdAt: newEventData.createdAt,
        };
        commit('populateEventByEventIndex', copy);
        return newEventData.id;
      }
      return storeEvent.id;
    },
    async createNewEvent({ commit }, partyKey: Party['key']) {
      try {
        const { data } = await createIncidentPartyEvent(partyKey);
        commit('createNewEvent', { partyKey, data });
        return true;
      } catch (e) {
        const { addSnackbar } = useSnackbar();
        const { tm } = i18n.global;
        addSnackbar({
          message: tm('requestErrors.party.unableToCreateIncidentPartyEvent'),
          type: 'error',
        });
        console.error(e);
        return Promise.reject(e);
      }
    },
    async populateEvent({ commit }, {
      partyKey,
      currentEvent,
    }: {
      partyKey: Party['key'],
      currentEvent: IncidentPartyEventGetResponseBody
    }) {
      try {
        const event: IncidentPartyEvent = {
          partyKey,
          id: currentEvent.id,
          images: [],
          address: currentEvent.address,
          isUnableToAddPhotos: currentEvent.isUnableToAddPhotos === 1,
          eventDateTime: currentEvent.eventDateTime,
          eventDescription: currentEvent.eventDescription,
          eventDescriptionDetails: currentEvent.eventDescriptionDetails,
          locationDescription: currentEvent.locationDescription,
          xCoordinate: currentEvent.xCoordinate,
          yCoordinate: currentEvent.yCoordinate,
          ehakCode: currentEvent.ehakCode,
          ehakKovCode: currentEvent.ehakKovCode,
          ehakMkCode: currentEvent.ehakMkCode,
          createdAt: currentEvent.createdAt,
          status: currentEvent.status,
          customDescCheckBoxValue: true,
          uploadedAudio: false,
        };

        // Custom index logic, since the returned array may also contain audio files.
        let index = -1;
        const { data: apiFiles } = await getIncidentPartyEventFiles(partyKey, currentEvent.id);
        event.images = apiFiles.reduce<ApiImage[]>(
          (stack, { imageThumbBase64, id, fileType }) => {
            if (fileType === 'AUDIO') {
              event.customDescCheckBoxValue = true;
              event.uploadedAudio = true;
              return stack;
            }
            index += 1;
            return [...stack, {
              base64: imageThumbBase64, index, id,
            }];
          }, [],
        );
        commit('populateEventByEventIndex', event);
      } catch (e) {
        console.error(e);
      }
    },
    async patchEvent({ getters, commit, dispatch }, {
      partyKey,
      eventId,
      obj,
    }: { obj: IncidentPartyEventPatchRequestBody, partyKey: Party['key'], eventId: IncidentPartyEvent['id'] }) {
      try {
        let patchEventId = eventId;
        const copy = { ...obj };
        const storeEvent = getters.getByPartyKey(partyKey);

        if (!Object.keys(copy).includes('isUnableToAddPhotos')) {
          copy.isUnableToAddPhotos = storeEvent.isUnableToAddPhotos;
        }

        // Check if any data has changed
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (Object.keys(copy).every((key) => copy[key] === storeEvent[key])) return true;

        patchEventId = await dispatch('maybeCreateNewEvent', partyKey);

        const { data } = await patchIncidentPartyEvent(partyKey, patchEventId, copy);
        commit('updateEvent', { partyKey, data });
        return true;
      } catch (e) {
        const { addSnackbar } = useSnackbar();
        const { tm } = i18n.global;
        addSnackbar({
          message: tm('requestErrors.party.unableToPatchIncidentPartyEvent'),
          type: 'error',
        });
        console.error(e);
        return false;
      }
    },
    async removePictureFromStore({ state, commit }, { partyKey, imageIndex }: { partyKey: Party['key'] } & ApiImageDeleteRequest) {
      const event = state.events.find((lEvent) => lEvent.partyKey === partyKey);
      if (!event) {
        return false;
      }
      const image = event.images.find(({ index }) => index === imageIndex);
      if (!image) {
        return false;
      }

      const success = await remove(partyKey, image.id);
      commit('removePictureFromStore', { partyKey, index: imageIndex });
      return success;
    },
  },
  getters: {
    all: (state) => state.events,
    getByPartyKey: (state) => (key: Party['key']) => state.events.find((event) => event.partyKey === key),
    getUploadedImagesByPartyKey: (state) => (key: Party['key']) => {
      const obj = state.events.find((event) => event.partyKey === key);
      if (obj !== undefined) return obj.images;
      return null;
    },
  },
});

export default eventModule;
