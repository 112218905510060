<template>
  <div
    v-if="isPopupDisplayed"
    class="lkf-cookie-bar--background"
  >
    <div class="lkf-cookie-bar max--width">
      <div class="lkf-cookie-bar__content">
        <h3 class="lkf-cookie-bar__title">
          {{ t('cookie-bar.title') }}
        </h3>
        <p class="lkf-cookie-bar__intro">
          {{ t('cookie-bar.content') }}
        </p>
        <div class="lkf-cookie-bar__data_title">
          <LKFButton
            variant="ghost"
            class="lkf-cookie-bar__data-protection-button"
            @click.prevent.stop="isDataProtectionOpen = true"
          >
            <strong>{{ t('cookie-bar.data-protection-title') }}</strong>
          </LKFButton>
        </div>
        <div class="lkf-cookie-bar__buttons">
          <LKFButton
            class="lkf-cookie-bar__buttons--agree"
            variant="secondary"
            @click="acceptCookies"
          >
            {{ t('cookie-bar.agree') }}
          </LKFButton>
          <LKFButton
            class="lkf-cookie-bar__buttons--disagree"
            @click="rejectCookies"
          >
            {{ t('cookie-bar.disagree') }}
          </LKFButton>
        </div>
      </div>
    </div>
    <DataProtection
      :is-open="isDataProtectionOpen"
      @modalClose="isDataProtectionOpen=false"
    />
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { Ref, ref } from 'vue';
import Cookies from 'js-cookie';
import useBodyOverflow from '@/util/useHooks/useBodyOverflow';
import LKFButton from '@/components/LKFButton.vue';
import DataProtection from '@/components/DataProtection.vue';

const { t } = useI18n();
const {
  hideOverflow,
  showOverflow,
} = useBodyOverflow();
let shouldShow = false;

if (typeof Cookies.get('GDPR-consent') === 'undefined') shouldShow = true;
const isPopupDisplayed: Ref<boolean> = ref(shouldShow);
const isDataProtectionOpen = ref(false);

if (isPopupDisplayed.value) {
  hideOverflow();
}

const closeCookieBar = () => {
  isPopupDisplayed.value = false;
  showOverflow();
};

const acceptCookies = () => {
  Cookies.set('GDPR-consent', '1', { expires: 365 });
  closeCookieBar();
};

const rejectCookies = () => {
  Cookies.set('GDPR-consent', '0');
  closeCookieBar();
};

</script>
