/* eslint-disable import/no-cycle */
import { Module } from 'vuex';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios';
import { State } from '@/store';
import {
  createIncidentParty,
  getIncidentParty,
  patchIncidentPartyInsurer,
  patchIncidentPartyResponsibility,
  patchIncidentPartyStatus,
  postIncidentPartyResponsibility,
} from '@/services/incidentParty';
import {
  CreateNewPartyRequestParams,
  FillerType,
  IncidentPartyResponseBody,
  MappedVehicle,
  ModalStatuses,
  Party,
  PartyModule,
  ResponsibilityObj,
  ResponsibilityPatchRequestBody,
  ResponsibilityPostRequestBody,
  ResponsibilityResponse,
} from '@/@types/incidentPartyService';
import useSnackbar from '@/util/useHooks/useSnackbar';
import i18n from '@/i18n';
import { CountryCode } from '@/@types/countries';
import {
  IncidentPartyPersonResponseBody,
  UpdatePersonRequest,
} from '@/@types/incidentPartyPersonService';
import { patchIncidentPartyPerson, postIncidentPartyPerson } from '@/services/incidentPartyPerson';
import { IncidentResponseParty } from '@/@types/incidentService';
import { IncidentPartyVehiclePutResponseBody } from '@/@types/incidentPartyVehicleService';
import { IncidentPartyEventGetResponseBody } from '@/@types/incidentPartyEventService';
import getCurrentOrPendingObj from '@/util/helpers/getCurrentOrPendingObj';
import { Insurer } from '@/@types/insuranceCompanyService';
import setSelfCheckValue from '@/util/helpers/setSelfCheckValue';
import { InsurerPatchData } from '@/@types/insurer';
import getInitiatorParticipantTypeCookieName from '@/shared/participant';
import Cookies from 'js-cookie';

const getIsUnableToAddPersonDetailsBoolean = (isUnableToAddPersonDetails: IncidentPartyPersonResponseBody['isUnableToAddPersonDetails']) => (typeof isUnableToAddPersonDetails === 'boolean' ? isUnableToAddPersonDetails : isUnableToAddPersonDetails === 1);

const partyModule: Module<PartyModule, State> = ({
  namespaced: true,
  state: () => ({ parties: [] }),
  mutations: {
    createNewParty(state, partyData: IncidentPartyResponseBody) {
      const cleanedData: Party = {
        id: partyData.id,
        key: partyData.key,
        isInitiator: partyData.isInitiator,
        fillerType: partyData.fillerType,
        qrCode: partyData.qrCode,
        status: partyData.status,
        person: {
          id: -1,
          personCode: '',
          email: '',
          firstName: '',
          lastName: '',
          countryCallCode: '+372',
          phoneCountryCode: 'EE',
          phone: '',
          isUnableToAddPersonDetails: false,
          status: 'CURRENT',
        },
        responsibility: null,
        otherPartyResponsibilities: [],
        skippedInsurer: false,
      };
      state.parties = [...state.parties, cleanedData];
    },
    populateParty(state, party: Party) {
      const index = state.parties.findIndex((sParty) => sParty.key === party.key);
      if (index !== -1) {
        state.parties[index] = party;
      } else {
        state.parties.push(party);
      }
    },
    removeParty(state, partyKey: Party['key']) {
      state.parties = state.parties.filter((party) => party.key !== partyKey);
    },
    clear(state) {
      state.parties = [];
    },
    setJointExecNumbData(state, obj: { key: Party['key'], number: string, code: CountryCode }) {
      const index = state.parties.findIndex((party: Party) => party.key === obj.key);
      if (index === -1) return false;
      state.parties[index].jointExecNumb = obj.number;
      state.parties[index].jointExecCountryCode = obj.code;
      return true;
    },
    setPartyStatus(state, obj: { key: Party['key'], newStatus: ModalStatuses }) {
      const index = state.parties.findIndex((party: Party) => party.key === obj.key);
      if (index === -1) return false;
      state.parties[index].status = obj.newStatus;
      return true;
    },
    setPartyInsurerInfo(state, obj: {
      partyKey: Party['key'],
      skippedInsurer: boolean,
      handlingInsurerId?: Insurer['id'],
      cascoInsurerId?: Insurer['id']
    }) {
      const index = state.parties.findIndex((party: Party) => party.key === obj.partyKey);
      if (index === -1) return false;
      state.parties[index].skippedInsurer = obj.skippedInsurer;
      state.parties[index].cascoInsurerId = obj.cascoInsurerId;
      state.parties[index].handlingInsurerId = obj.handlingInsurerId;
      return true;
    },
    setPartyPersonData(state, {
      partyKey,
      data,
    }: { partyKey: Party['key'], data: IncidentPartyPersonResponseBody }) {
      const index = state.parties.findIndex((party: Party) => party.key === partyKey);
      if (index === -1) return false;
      const {
        id,
        personCode,
        firstName,
        lastName,
        email,
        countryCallCode,
        phoneCountryCode,
        phone,
        isUnableToAddPersonDetails,
        status,
      } = data;
      state.parties[index].person = {
        ...state.parties[index].person,
        id,
        firstName,
        lastName,
        email,
        countryCallCode,
        phoneCountryCode,
        phone,
        isUnableToAddPersonDetails:
          getIsUnableToAddPersonDetailsBoolean(isUnableToAddPersonDetails),
        personCode,
        status,
      };
      return true;
    },
    setPartyResponsibility(state, {
      index,
      obj,
    }: { index: number, obj: ResponsibilityResponse }) {
      state.parties[index].responsibility = {
        id: obj.id,
        status: obj.status,
        responsibility: obj.responsibility as ResponsibilityObj['responsibility'],
      };
      return true;
    },
    setPartyOtherResponsibilities(state, {
      partyIndex,
      responsibilities,
    }: { partyIndex: number, responsibilities: ResponsibilityResponse[] }) {
      if (partyIndex < 0) return false;
      state.parties[partyIndex].otherPartyResponsibilities = responsibilities;
      return true;
    },
    setPartiesFillerType(state, primaryFillerType: Party['fillerType']) {
      state.parties[0].fillerType = primaryFillerType;
      state.parties[1].fillerType = primaryFillerType === 'MOTOR_VEHICLE' ? 'PROPERTY' : 'MOTOR_VEHICLE';
    },
    setPartyFillerType(state, {
      partyKey,
      fillerType,
    }: { partyKey: Party['key'], fillerType: Party['fillerType'] }) {
      const index = state.parties.findIndex((party: Party) => party.key === partyKey);
      if (index === -1) return false;
      state.parties[index].fillerType = fillerType;
      return true;
    },
  },
  actions: {
    setPartyResponsibility({
      state,
      rootState,
      commit,
    }, obj: ResponsibilityResponse) {
      const index = state.parties.findIndex(
        (party: Party) => party.key === obj.incidentPartyKey,
      );
      if (index === -1) return false;

      const partyVehicle = rootState.vehicles.vehicles.find(
        (vehicle) => vehicle.partyKey === obj.incidentPartyKey,
      );

      if (!partyVehicle) return false;
      if (partyVehicle.vehicleId !== obj.vehicle.id) return false;

      commit('setPartyResponsibility', {
        index,
        obj,
      });
      return true;
    },
    setPartyOtherResponsibilities({
      commit,
      state,
      rootState,
    }, {
      partyKey,
      responsibilities,
    }: { partyKey: Party['key'], responsibilities: ResponsibilityResponse[] }) {
      const index = state.parties.findIndex(
        (party: Party) => party.key === partyKey,
      );
      if (index === -1) return false;

      const partyVehicle = rootState.vehicles.vehicles.find(
        (vehicle) => vehicle.partyKey === partyKey,
      );

      if (!partyVehicle) return false;

      commit('setPartyOtherResponsibilities', {
        partyIndex: index,
        responsibilities: responsibilities.filter(
          (obj) => obj.vehicle.id !== partyVehicle.vehicleId,
        ),
      });
      return true;
    },
    async createNewParty({
      commit,
      rootState,
    }, {
      incidentKey,
      fillerType,
      locale,
      isInitiator = false,
    }: CreateNewPartyRequestParams) {
      try {
        const {
          data,
        } = await createIncidentParty(incidentKey, isInitiator, locale);

        data.fillerType = fillerType ?? 'MOTOR_VEHICLE';
        commit('createNewParty', data);
        commit('incident/setDbPartiesCount', rootState.incident.dbPartiesCount + 1);
        return data.key;
      } catch (e) {
        console.error(e);
        const { addSnackbar } = useSnackbar();
        const { tm } = i18n.global;

        addSnackbar({
          message: tm('requestErrors.incident.unableToCreateApiIncidentParty'),
          type: 'error',
        });

        return null;
      }
    },
    async getParty({ commit }, partyKey: Party['key']) {
      try {
        const {
          data,
        } = await getIncidentParty(partyKey);

        data.fillerType = 'MOTOR_VEHICLE';
        commit('createNewParty', data);
        return data.key;
      } catch (e) {
        console.error(e);
        const { addSnackbar } = useSnackbar();
        const { tm } = i18n.global;

        addSnackbar({
          message: tm('requestErrors.incident.unableToGetApiIncidentParty'),
          type: 'error',
        });

        return null;
      }
    },
    async populateParty({
      commit,
      dispatch,
    }, {
      incidentKey,
      partyData,
    }: {
      incidentKey: string, partyData: IncidentResponseParty
    }) {
      // Set up the basic Party object
      const party: Party = {
        key: partyData.key,
        isInitiator: partyData.isInitiator === 1,
        fillerType:
          (
            Cookies.get(
              getInitiatorParticipantTypeCookieName(incidentKey, partyData.key),
            ) as FillerType | undefined
          ) ?? 'MOTOR_VEHICLE',
        status: partyData.status,
        person: {
          id: -1,
          personCode: '',
          email: '',
          firstName: '',
          lastName: '',
          countryCallCode: '+372',
          phoneCountryCode: 'EE',
          phone: '',
          isUnableToAddPersonDetails: false,
          status: 'DELETED',
        },
        responsibility: null,
        handlingInsurerId: partyData.handlingInsurerId,
        cascoInsurerId: partyData.cascoInsurerId,
        skippedInsurer: partyData.skippedInsurer ?? false,
        otherPartyResponsibilities: [],
      };

      // Populate the party person data if possible
      if (partyData.persons.length) {
        const currentPerson = getCurrentOrPendingObj<IncidentPartyPersonResponseBody>(
          partyData.persons,
        );
        if (currentPerson) {
          party.person = {
            id: currentPerson.id,
            personCode: currentPerson.personCode,
            email: currentPerson.email,
            firstName: currentPerson.firstName,
            lastName: currentPerson.lastName,
            countryCallCode: currentPerson.countryCallCode,
            phoneCountryCode: currentPerson.phoneCountryCode,
            phone: currentPerson.phone,
            isUnableToAddPersonDetails:
              getIsUnableToAddPersonDetailsBoolean(currentPerson.isUnableToAddPersonDetails),
            status: currentPerson.status,
          };
        } else {
          console.debug('No person found for party: ', partyData.key);
        }
      }

      commit('populateParty', party);
      // Populate the party vehicle data if possible
      if (partyData.vehicles.length) {
        const currentVehicle = getCurrentOrPendingObj<IncidentPartyVehiclePutResponseBody>(
          partyData.vehicles,
        );
        if (currentVehicle) {
          currentVehicle.vehicleType = !currentVehicle.vehicleType ? 'MOTOR_VEHICLE' : currentVehicle.vehicleType;
          commit('setPartyFillerType', {
            partyKey: partyData.key,
            fillerType: (
              currentVehicle.vehicleType === 'PROPERTY' && currentVehicle.propertyName === 'PROPERTY' ? 'PROPERTY' : party.fillerType
            ),
          });
          await dispatch('vehicles/populateVehicle', {
            partyKey: party.key,
            currentVehicle,
          }, { root: true });
        } else {
          console.debug('No vehicle found for party: ', partyData.key);
          commit('setPartyFillerType', {
            partyKey: partyData.key,
            fillerType: 'PROPERTY',
          });
        }
      }
      if (partyData.events.length) {
        const currentEvent = getCurrentOrPendingObj<IncidentPartyEventGetResponseBody>(
          partyData.events,
        );
        if (currentEvent) {
          await dispatch(
            'events/populateEvent',
            {
              partyKey: party.key,
              currentEvent,
            }, { root: true },
          );
        } else {
          console.debug('No event found for party: ', partyData.key);
        }
      }
    },
    async removeParty({ commit }, partyKey: Party['key']) {
      try {
        // await patchIncidentPartyStatus(partyKey, 'DELETED');
        commit('removeParty', partyKey);
        return true;
      } catch (e) {
        console.error(e);
        const { addSnackbar } = useSnackbar();
        const { tm } = i18n.global;

        addSnackbar({
          message: tm('requestErrors.party.unableToRemoveParty'),
          type: 'error',
        });
        return false;
      }
    },
    async postPartyPersonData({ commit }, {
      partyKey,
      ...postData
    }: UpdatePersonRequest) {
      try {
        const {
          data,
        } = await postIncidentPartyPerson(partyKey, postData);
        commit('setPartyPersonData', {
          partyKey,
          data,
        });
        return true;
      } catch (e) {
        console.error(e);
        const { addSnackbar } = useSnackbar();
        const { tm } = i18n.global;

        addSnackbar({
          message: tm('requestErrors.party.unableToPostPerson'),
          type: 'error',
        });
        return false;
      }
    },
    async patchPartyPersonData({
      getters,
      commit,
    }, {
      partyKey,
      id,
      ...postData
    }: UpdatePersonRequest) {
      try {
        const storeParty: Party = getters.getParty(partyKey);

        // Cancel out if nothing has been changed
        if (
          Object.keys(postData)
            .every((key) =>
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line implicit-arrow-linebreak
              postData[key] === storeParty.person[key])
        ) {
          return true;
        }

        if (storeParty.person?.status === 'PENDING') {
          const {
            data,
          } = await postIncidentPartyPerson(partyKey, postData);
          commit('setPartyPersonData', {
            partyKey,
            data,
          });
        } else {
          const {
            data,
          } = await patchIncidentPartyPerson(partyKey, id ?? -1, postData);
          commit('setPartyPersonData', {
            partyKey,
            data,
          });
        }
        return true;
      } catch (e) {
        console.error(e);
        const { addSnackbar } = useSnackbar();
        const { tm } = i18n.global;

        addSnackbar({
          message: tm('requestErrors.party.unableToPostPerson'),
          type: 'error',
        });
        return false;
      }
    },
    async patchResponsibilities({ dispatch }, {
      partyKey,
      mappedVehicles,
    }:
      { partyKey: Party['key'], mappedVehicles: MappedVehicle[] }) {
      try {
        // eslint-disable-next-line max-len
        const patchData = mappedVehicles.map<ResponsibilityPatchRequestBody | ResponsibilityPostRequestBody>(
          (mappedVehicle) => ({
            responsibility: mappedVehicle.responsibility ?? 'NOT_RESPONSIBLE',
            incidentVehicleId: mappedVehicle.vehicleId,
            responsibilityId: mappedVehicle.responsibilityStatus !== 'CURRENT' ? undefined : mappedVehicle.responsibilityId ?? -1,
          }),
        );
        let data: ResponsibilityResponse[] = [];
        // If all responsibility values are pending then post all else patch all
        if (
          patchData.some(
            (obj) => (obj as ResponsibilityPatchRequestBody).responsibilityId === undefined,
          )
        ) {
          data = (await postIncidentPartyResponsibility(partyKey, patchData)).data;
        } else {
          data = (await patchIncidentPartyResponsibility(
            partyKey, patchData as ResponsibilityPatchRequestBody[],
          )).data;
        }

        const withIncidentPartyKeyMapped = data.map((responsibilityResponse) => {
          const copy = { ...responsibilityResponse };
          if (!copy.incidentPartyKey) copy.incidentPartyKey = partyKey;
          dispatch('setPartyResponsibility', copy);
          return copy;
        });

        dispatch('setPartyOtherResponsibilities', {
          partyKey,
          responsibilities: withIncidentPartyKeyMapped,
        });

        return true;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      } catch (e: AxiosError) {
        console.error(e);
        const { addSnackbar } = useSnackbar();
        const { tm } = i18n.global;

        addSnackbar({
          message: tm('requestErrors.party.unableToPatchResponsibilities'),
          type: 'error',
        });
        return false;
      }
    },
    async postResponsibilities(
      { dispatch },
      {
        partyKey,
        mappedVehicles,
      }: { partyKey: Party['key'], mappedVehicles: MappedVehicle[] },
    ) {
      try {
        const postData = mappedVehicles.map<ResponsibilityPostRequestBody>((mappedVehicle) => ({
          responsibility: mappedVehicle.responsibility ?? 'NOT_RESPONSIBLE',
          incidentVehicleId: mappedVehicle.vehicleId,
        }));

        const { data } = await postIncidentPartyResponsibility(partyKey, postData);

        const withIncidentPartyKeyMapped = data.map((responsibilityResponse) => {
          const copy = { ...responsibilityResponse };
          if (!copy.incidentPartyKey) copy.incidentPartyKey = partyKey;
          dispatch('setPartyResponsibility', copy);
          return copy;
        });

        dispatch('setPartyOtherResponsibilities', {
          partyKey,
          responsibilities: withIncidentPartyKeyMapped,
        });

        return true;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      } catch (e: AxiosError) {
        console.error(e);
        const { addSnackbar } = useSnackbar();
        const { tm } = i18n.global;

        addSnackbar({
          message: tm('requestErrors.party.unableToPatchResponsibilities'),
          type: 'error',
        });
        return false;
      }
    },
    async patchPartyStatus({ commit }, {
      incidentKey,
      partyKey,
    }) {
      try {
        const { data } = await patchIncidentPartyStatus();
        data.parties.forEach(({
          key,
          status,
        }) => {
          commit('setPartyStatus', {
            key,
            newStatus: status,
          });
        });
        setSelfCheckValue(incidentKey, partyKey);

        return true;
      } catch (e) {
        const { addSnackbar } = useSnackbar();
        const { tm } = i18n.global;

        addSnackbar({
          message: tm('requestErrors.party.unableToPatchPartyStatus'),
          type: 'error',
        });
        return false;
      }
    },
    async patchInsurer({ commit }, data: InsurerPatchData) {
      try {
        const { data: patchResponse } = await patchIncidentPartyInsurer(
          data.partyKey,
          {
            handlingInsurerId: data.handlingInsurerId,
            cascoInsurerId: data.cascoInsurerId,
            cascoInsurerRegCode: data.cascoInsurerRegCode,
            vehicleId: data.vehicleId,
            skippedInsurer: data.skippedInsurer,
          },
        );

        commit('setPartyInsurerInfo', {
          partyKey: data.partyKey,
          handlingInsurerId: patchResponse.handlingInsurerId,
          cascoInsurerId: patchResponse.cascoInsurerId,
          skippedInsurer: data.skippedInsurer,
        });
        return true;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      } catch (e: AxiosError) {
        const { addSnackbar } = useSnackbar();
        const { tm } = i18n.global;

        addSnackbar({
          message: tm('requestErrors.party.unableToPatchPartyInsurer'),
          type: 'error',
        });
        return false;
      }
    },
  },
  getters: {
    all: (state) => state.parties,
    getParty: (state) => (key: Party['key']) => state.parties.find((party) => party.key === key),
    getPartyIndex: (state) => (key: Party['key']) => state.parties.findIndex((party) => party.key === key),
    getNextParty: (state) => (key: Party['key']) => {
      // Find party
      const currPartyIndex = state.parties.findIndex((party) => party.key === key);
      // No party found
      if (currPartyIndex === -1) return false;
      // Last party found, no next party
      if (currPartyIndex === state.parties.length - 1) return null;
      // Return party
      return state.parties[currPartyIndex + 1];
    },
    getPrevParty: (state) => (key: Party['key']) => {
      // Find party
      const currPartyIndex = state.parties.findIndex((party) => party.key === key);
      // No party found
      if (currPartyIndex === -1) return false;
      // first party found, no next party
      if (currPartyIndex === 0) return null;
      // Return party
      return state.parties[currPartyIndex - 1];
    },
    getFirstParty: (state) => state.parties[0],
    getLastParty: (state) => state.parties[state.parties.length - 1],
    getInitiator: (state) => state.parties.find((party) => party.isInitiator),
    getIncidentMessageStatus: (state) => state.parties,
  },
});

export default partyModule;
