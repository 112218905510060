interface UseBodyOverflow {
  hideOverflow(): void
  showOverflow(): void
}

export default function useBodyOverflow(): UseBodyOverflow {
  const className = 'overflow--hidden';
  const hideOverflow = () => {
    if (window?.document?.body) {
      window.document.body.classList.add(className);
    }
  };

  const showOverflow = () => {
    if (window?.document?.body) {
      window.document.body.classList.remove(className);
    }
  };

  return {
    hideOverflow,
    showOverflow,
  };
}
