/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';
import api from '@/util/api';
import {
  ForeignVehicleUpdateData,
  IncidentPartyVehicleCreateResponseBody,
  IncidentPartyVehiclePutResponseBody,
  IncidentPartyVehicleResponseBody,
  UnregisteredVehicleUpdateData,
  Vehicle, VehicleImagesResponse,
} from '@/@types/incidentPartyVehicleService';
import { IncidentEvent, Party } from '@/@types/incidentPartyService';

export const getIncidentPartyVehicle = async (partyKey: Party['key'], vehicleId: Vehicle['vehicleId']): Promise<AxiosResponse<IncidentPartyVehicleResponseBody>> => api.get<IncidentPartyVehicleResponseBody>(`/incident/party/${partyKey}/vehicle/${vehicleId}`);

export const getIncidentPartyVehicleImages = async (partyKey: Party['key'], vehicleId: Vehicle['vehicleId']): Promise<AxiosResponse<VehicleImagesResponse>> => api.get<VehicleImagesResponse>(`/incident/party/${partyKey}/vehicle/${vehicleId}/files`);

export const createIncidentPartyVehicle = async (partyKey: Party['key']): Promise<AxiosResponse<IncidentPartyVehicleCreateResponseBody>> => api.post<IncidentPartyVehicleCreateResponseBody>(`/incident/party/${partyKey}/vehicle`);

export const patchIncidentPartyVehicleRegNr = async (
  partyKey: Party['key'], vehicleId: Vehicle['vehicleId'], registrationNr: Vehicle['registrationNr'], isUnableToAddPhotos: Vehicle['isUnableToAddPhotos'],
  eventDateTime: IncidentEvent['eventDateTime'] | undefined,
): Promise<AxiosResponse<IncidentPartyVehiclePutResponseBody>> => api.patch<IncidentPartyVehiclePutResponseBody>(`/incident/party/${partyKey}/vehicle/${vehicleId}/registration_nr`, {
  registrationNr,
  isUnableToAddPhotos,
  eventDateTime,
});

export const putIncidentPartyForeignVehicle = async ({ partyKey, vehicleId, ...putData }: ForeignVehicleUpdateData): Promise<AxiosResponse<IncidentPartyVehicleResponseBody>> => api.put<IncidentPartyVehicleResponseBody>(`/incident/party/${partyKey}/vehicle/${vehicleId}`, putData);

export const putIncidentPartyUnregisteredVehicle = async ({ partyKey, vehicleId, ...putData }: UnregisteredVehicleUpdateData): Promise<AxiosResponse<IncidentPartyVehiclePutResponseBody>> => api.put<IncidentPartyVehiclePutResponseBody>(`/incident/party/${partyKey}/vehicle/${vehicleId}/property`, putData);
