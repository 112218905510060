import { ref, Ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import i18n from '@/i18n';

type ButtonCallback = () => void

type SnackBarOptions = {
  message: string,
  type: 'notice' | 'error' | 'success' | '',
  key?: string,
  timeout?: number,
  buttonText?: string,
  buttonCallback?: ButtonCallback,
}

export type UseSnackbar = {
  snackbars: Ref<SnackBarOptions[]>,
  addSnackbar(snackBar: SnackBarOptions): void
}

const snackbars: UseSnackbar['snackbars'] = ref([]);

const removeSnackbar = (copy: SnackBarOptions) => {
  console.debug('Removed snackbar: ', copy);
  snackbars.value = snackbars.value.filter(
    (snack) => snack.key !== copy.key,
  );
};

const addSnackbar: UseSnackbar['addSnackbar'] = (snackBar: SnackBarOptions) => {
  const copy: SnackBarOptions = { ...snackBar };
  const { tm } = i18n.global;
  // Set up the defaults
  copy.timeout = copy.timeout ?? 5000;
  copy.key = uuidv4();
  // Prevent duplicate snackbars
  snackbars.value = snackbars.value.filter(({ message }) => message !== copy.message);
  console.debug('Pushed new snackbar: ', copy);
  if (copy.timeout === 0) {
    copy.buttonText = tm('snackbar.close');
    copy.buttonCallback = () => removeSnackbar(copy);
  }
  snackbars.value = [...snackbars.value, copy];
  if (copy.timeout) {
    setTimeout(() => removeSnackbar(copy), copy.timeout);
  }
};
export default function useSnackbar(): UseSnackbar {
  return { snackbars, addSnackbar };
}
