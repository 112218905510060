<template>
  <button
    :class="['lkf-button', ...btnClassList]"
    :disabled="disabled"
    :value="value"
    :type="type"
  >
    <Icon
      v-if="iconName"
      :icon-name="iconName"
      class="lkf-button__icon"
    />
    <div class="lkf-button__text">
      <slot />
    </div>
  </button>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';

export default defineComponent({
  name: 'LKFButton',
  props: {
    variant: {
      type: String,
      validator: (value: string) => ['primary', 'secondary', 'ghost', 'icon'].includes(value),
      default: 'primary',
    },
    disabled: { type: Boolean, default: false },
    iconName: { type: String, default: '' },
    size: { type: String, default: '' },
    subtleIconBtn: { type: Boolean, default: false },
    value: { type: String, default: '' },
    type: { type: String, default: 'button' },
  },
  components: { Icon },

  setup(props) {
    function getBtnClassList(): string[] {
      const {
        size, variant, iconName, subtleIconBtn,
      } = props;
      const btnClassList = [];
      btnClassList.push(`lkf-button--${variant}`);

      if (variant === 'icon') {
        if (!iconName) throw new Error('Please provide a prop "icon-name"');
        if (size) throw new Error('You can not use the "size" prop on btn variant "icon"');
        if (subtleIconBtn) btnClassList.push('lkf-button--icon--subtle');
      } else if (subtleIconBtn) {
        throw new Error('You can only add variant "icon" and "subtle-icon-button" prop together');
      } else if (iconName) {
        btnClassList.push('lkf-button--spacing');
      }

      if (size) btnClassList.push(`lkf-button--${size}`);

      return btnClassList;
    }

    const btnClassList = computed(getBtnClassList);

    return { btnClassList };
  },
});
</script>
