/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';
import api from '@/util/api';
import {
  IncidentPartyEvent,
  IncidentPartyCreateEventResponseBody,
  IncidentPartyEventPatchResponseBody,
  IncidentPartyEventGetResponseBody,
  IncidentPartyEventPatchRequestBody,
  IncidentPartyEventFilesResponseBody,
} from '@/@types/incidentPartyEventService';
import { Party } from '@/@types/incidentPartyService';

export const getIncidentPartyEvent = async (partyKey: Party['key'], eventId: IncidentPartyEvent['id']): Promise<AxiosResponse<IncidentPartyEventGetResponseBody>> => api.get<IncidentPartyEventGetResponseBody>(`/incident/party/${partyKey}/event/${eventId}`);

export const getIncidentPartyEventFiles = async (partyKey: Party['key'], eventId: IncidentPartyEvent['id']): Promise<AxiosResponse<IncidentPartyEventFilesResponseBody>> => api.get<IncidentPartyEventFilesResponseBody>(`/incident/party/${partyKey}/event/${eventId}/files`);

export const createIncidentPartyEvent = async (partyKey: Party['key']): Promise<AxiosResponse<IncidentPartyCreateEventResponseBody>> => api.post<IncidentPartyCreateEventResponseBody>(`/incident/party/${partyKey}/event`);

export const patchIncidentPartyEvent = async (partyKey: Party['key'], eventId: IncidentPartyEvent['id'], data: IncidentPartyEventPatchRequestBody): Promise<AxiosResponse<IncidentPartyEventPatchResponseBody>> => api.patch(`/incident/party/${partyKey}/event/${eventId}`, data);
