<template>
  <div :class="`snackbar snackbar--${type}`">
    <Icon
      :icon-name="iconName"
      v-if="iconName !== ''"
      class="snackbar__icon"
    />
    <span class="snackbar__text">
      {{ message }}
    </span>
    <LKFButton
      v-if="buttonText"
      variant="ghost"
      @click="buttonCallback"
    >
      {{ buttonText }}
    </LKFButton>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import Icon from '@/components/Icon.vue';
import LKFButton from '@/components/LKFButton.vue';

export default defineComponent({
  name: 'LKFSnackbar',
  components: { LKFButton, Icon },
  props: {
    type: { type: String, default: 'notice' },
    message: { type: String, required: true },
    buttonText: {
      type: String,
      default: '',
    },
    buttonCallback: {
      type: Function,
      default: () => console.debug('snackbar button clicked'),
    },
  },
  setup(props) {
    // Inline auto called function since no ternary if-s
    const iconName = ref((() => {
      if (props.type === 'success') {
        return 'check-circle';
      } else if (props.type === 'error') {
        return 'alert-triangle';
      }
      return '';
    })());

    return {
      iconName,
    };
  },
});
</script>
