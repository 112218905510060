// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError, AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import { Store } from 'vuex';
import api from '@/util/api';
import useSnackbar from '@/util/useHooks/useSnackbar';
import i18n from '@/i18n';
import { Party } from '@/@types/incidentPartyService';
import { ApiImage } from '@/@types/incidentPartyEventService';
import { FilePostResponse } from '@/@types/imageUploadComponent';
// eslint-disable-next-line import/no-cycle
import { State } from '@/store';

export const upload = async (file: File, cb: ((e: AxiosProgressEvent) => void) | null, options: {
  endpoint: string,
  type: 'IMAGE' | 'AUDIO'
  capturePosition?: string,
  orientation?: number,
  storeBase: 'vehicles' | 'events',
  store: Store<State>,
}): Promise<false | FilePostResponse> => {
  // First determine if we need to update any dependencies
  const [,,, partyKey] = options.endpoint.split('/');
  if (options.storeBase === 'vehicles') {
    const vehicleId = await options.store.dispatch('vehicles/maybeCreateNewVehicle', partyKey);
    // eslint-disable-next-line no-param-reassign
    options.endpoint = `/incident/party/${partyKey}/vehicle/${vehicleId}/upload`;
  } else {
    const eventId = await options.store.dispatch('events/maybeCreateNewEvent', partyKey);
    // eslint-disable-next-line no-param-reassign
    options.endpoint = `/incident/party/${partyKey}/event/${eventId}/upload`;
  }

  const formData = new FormData();
  formData.append('file', file, file.name);
  formData.append('fileType', options.type);
  if (options.type === 'IMAGE' && options.capturePosition) formData.append('capturePosition', options.capturePosition);
  try {
    const config: AxiosRequestConfig<FormData> = {};
    if (cb !== null) config.onUploadProgress = cb;
    const { data }: {
      data: FilePostResponse
    } = await api.postForm(options.endpoint, formData, config);
    return data;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } catch (error: AxiosError) {
    console.error(error);
    if (error.response?.status === 413) {
      const { addSnackbar } = useSnackbar();
      const { tm } = i18n.global;

      addSnackbar({
        message: tm('requestErrors.upload.payloadTooLarge'),
        type: 'error',
      });
    }
    return false;
  }
};
export const remove = async (partyKey: Party['key'], imageId: ApiImage['id']): Promise<boolean> => {
  try {
    await api.delete(`/incident/party/${partyKey}/files/${imageId}`);
    return true;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } catch (error: AxiosError) {
    console.error(error);
    const { addSnackbar } = useSnackbar();
    const { tm } = i18n.global;

    addSnackbar({
      message: tm('requestErrors.upload.unableToRemoveFile'),
      type: 'error',
    });
    return false;
  }
};

export default { upload, remove };
