import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["name", "value"]
const _hoisted_2 = { class: "lkf-select__dropdown" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LKFInput = _resolveComponent("LKFInput")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "el",
    class: _normalizeClass(["lkf-select", [
      {'lkf-select--disabled': _ctx.disabled},
      {'lkf-select--small': _ctx.small}
    ]])
  }, [
    _createVNode(_component_LKFInput, {
      "caption-text": _ctx.captionText,
      "error-text": _ctx.errorMessage,
      "icon-after": _ctx.getIcon,
      "icon-before": _ctx.iconBefore,
      "label-text": _ctx.labelText,
      name: _ctx.selectName,
      "no-border": _ctx.noBorder,
      placeholder: _ctx.placeholder.toString(),
      "read-only": _ctx.notSearchable,
      required: _ctx.required,
      disabled: _ctx.disabled,
      pattern: _ctx.pattern,
      "model-value": _ctx.inputValue !== null ? _ctx.inputValue : _ctx.chosenOption[_ctx.displayProperty]?.toString(),
      class: "lkf-select__top",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleList())),
      onInput: _ctx.inputChange
    }, null, 8, ["caption-text", "error-text", "icon-after", "icon-before", "label-text", "name", "no-border", "placeholder", "read-only", "required", "disabled", "pattern", "model-value", "onInput"]),
    (_ctx.returnProperty !== 'object')
      ? (_openBlock(), _createElementBlock("input", {
          key: 0,
          name: _ctx.selectName + '_value',
          value: _ctx.chosenOption[_ctx.returnProperty],
          type: "hidden"
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayOptionsArray, (item) => {
        return _withDirectives((_openBlock(), _createElementBlock("li", {
          key: item[_ctx.returnProperty] && '' || '',
          class: _normalizeClass(['lkf-select__dropdown__item',
                 {'lkf-select__dropdown__item--selected': item === _ctx.chosenOption }]),
          onClick: ($event: any) => (_ctx.selectItem(item))
        }, [
          _renderSlot(_ctx.$slots, "li-icon-before", {
            displayValue: item[_ctx.displayProperty],
            returnValue: _ctx.getReturnValue(item)
          }, () => [
            _createTextVNode(_toDisplayString(item[_ctx.displayProperty]), 1)
          ])
        ], 10, _hoisted_3)), [
          [_vShow, item.visible || typeof item.visible === 'undefined']
        ])
      }), 128))
    ], 512), [
      [_vShow, _ctx.isOpen && _ctx.hasResults]
    ])
  ], 2))
}