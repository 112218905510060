/* eslint-disable camelcase */
import axios, { AxiosError, AxiosInstance } from 'axios';
import { useRouter } from 'vue-router';
import Cookies from 'js-cookie';
import i18n from '@/i18n';
import useSnackbar from '@/util/useHooks/useSnackbar';
// eslint-disable-next-line import/no-cycle
import { refreshAuthentication } from '@/services/auth';

let tokensRefreshing = false;
const HTTP_UNAUTHORIZED = 401;

const api: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Content-type': 'application/json',
    accept: 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    // Wow that's some weird ignores, but since axios doesn't make a setHeaders
    // function available i have to ignore the errors and write straight to the Record
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = Cookies.get('token') ?? '';
    return config;
  },
);
api.interceptors.response.use((res) => res,
  async (err) => {
    if (err.response) {
      if (err.response.status === HTTP_UNAUTHORIZED && !err.config.url.endsWith('/auth/incident/renewal')) {
        console.info('Interceptor caught unauthorized request', err.config);

        if (tokensRefreshing) {
          // Await for the previous refresh request to finish
          console.info('Multiple requests wish to update tokens. Awaiting for primary request to finish.');
          console.debug('Request: ', err.config);
          // Return a new promise since this allows for wherever the original request was called
          // to keep handling promises correct.
          return new Promise((resolve, reject) => {
            // The interval that keeps waiting for the token refresh to finish
            const interval = setInterval(async () => {
              console.debug('Checking if tokens refreshed: ', !tokensRefreshing, err.config);
              if (!tokensRefreshing) {
                // Clear the interval so that it won't keep looping old requests
                clearInterval(interval);
                // Do the actual response again.
                await api.request(err.config)
                  .then((retriedRes) => resolve(retriedRes))
                  .catch((retriedErr) => reject(retriedErr));
              }
            }, 200);
          });
        }

        try {
        // Set the boolean to true so that other request wont call this part
          tokensRefreshing = true;

          return refreshAuthentication()
            .then((apiResponse) => {
              tokensRefreshing = false;
              Cookies.set('token', apiResponse.data.access_token);
              Cookies.set('refreshToken', apiResponse.data.refresh_token);

              console.info('Retrying request: ', err.config.url, apiResponse);
              // Retry the original request
              return api.request(err.config);
            })
            .catch((apiResponse: AxiosError) => {
              tokensRefreshing = false;
              console.error(apiResponse);
              if (apiResponse.response?.status === HTTP_UNAUTHORIZED) {
                console.warn('/auth/incident/renewal request failed to update tokens.');
              }
              return Promise.reject(err);
            });
        } catch (e) {
          const { tm } = i18n.global;
          const { addSnackbar } = useSnackbar();
          addSnackbar({
            message: tm('requestErrors.incident.unableToRefreshAuthenticatedUser'),
            type: 'error',
          });

          const { push } = useRouter();
          // Do not await this, since wherever the request was called might have it's own catch
          push({ name: 'index' });
          return Promise.reject(e);
        }
      }
    } else {
      const { tm } = i18n.global;
      const { addSnackbar } = useSnackbar();
      addSnackbar({
        message: tm('requestErrors.api.internalServerError'),
        type: 'error',
        timeout: 10000,
      });
    }

    // Reject the request since it failed for other reasons
    return Promise.reject(err);
  });

export default api;
