import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Cookies from 'js-cookie';
import Layout from '@/components/Layout.vue';
import { maybeUpdateLang } from '@/util/router';
import { authenticateTokens } from '@/services/auth';
import { AuthRequestBody } from '@/@types/authService';
import { store } from '@/store';
import { Party } from '@/@types/incidentPartyService';
import setSelfCheckValue from '@/util/helpers/setSelfCheckValue';

// const Playground = () => import('@/views/Playground.vue');
const Participants = () => import('@/views/Participants.vue');
const JointExecution = () => import('@/views/JointExecution.vue');
const Vehicle = () => import('@/views/Vehicle.vue');
const ForeignVehicle = () => import('@/views/ForeignVehicle.vue');
const UnRegisteredVehicle = () => import('@/views/UnRegisteredVehicle.vue');
const ScenePictures = () => import('@/views/ScenePictures.vue');
const SceneDescription = () => import('@/views/SceneDescription.vue');
const SceneDescriptionDetails = () => import('@/views/SceneDescriptionDetails.vue');
const SceneLocation = () => import('@/views/SceneLocation.vue');
const DriverInfo = () => import('@/views/DriverInfo.vue');
const Responsibility = () => import('@/views/Responsibility.vue');
const Conclusion = () => import('@/views/Conclusion.vue');
const Confirmation = () => import('@/views/Confirmation.vue');
const Confirmed = () => import('@/views/Confirmed.vue');
const NotFound = () => import('@/views/404.vue');
const Index = () => import('@/views/Index.vue');

const routes: RouteRecordRaw[] = [
  {
    path: '/:locale',
    component: Layout,
    children: [
      {
        path: ':',
        name: 'Index',
        component: Index,
        meta: {
          protected: false,
        },
      },
      // {
      //   path: 'playground',
      //   name: 'playground',
      //   component: Playground,
      // },
      {
        path: 'participants',
        name: 'Participants',
        component: Participants,
        meta: {
          step: 1,
          protected: false,
        },
      },
      {
        path: ':incidentKey/:partyKey',
        name: 'JointAuth',
        redirect: {
          name: 'Index',
        },
        component: {
          template: '<router-view />',
        },
        children: [
          {
            path: 'joint-execution',
            name: 'JointExecution',
            component: JointExecution,
            meta: {
              step: 2,
              protected: true,
            },
          },
          {
            path: 'vehicle',
            name: 'Vehicle',
            component: Vehicle,
            meta: {
              step: 3,
              protected: true,
            },
          },
          {
            path: 'foreign-vehicle',
            name: 'ForeignVehicle',
            component: ForeignVehicle,
            meta: {
              step: 4,
              protected: true,
            },
          },
          {
            path: 'unregistered-vehicle',
            name: 'UnregisteredVehicle',
            component: UnRegisteredVehicle,
            meta: {
              step: 4,
              protected: true,
            },
          },
          {
            path: 'scene-pictures',
            name: 'ScenePictures',
            component: ScenePictures,
            meta: {
              step: 5,
              protected: true,
            },
          },
          {
            path: 'scene-description',
            name: 'SceneDescription',
            component: SceneDescription,
            meta: {
              step: 6,
              protected: true,
            },
          },
          {
            path: 'scene-description-details',
            name: 'SceneDescriptionDetails',
            component: SceneDescriptionDetails,
            meta: {
              step: 6,
              protected: true,
            },
          },
          {
            path: 'scene-location',
            name: 'SceneLocation',
            component: SceneLocation,
            meta: {
              step: 7,
              protected: true,
            },
          },
          {
            path: 'driver-info',
            name: 'DriverInfo',
            component: DriverInfo,
            meta: {
              step: 8,
              protected: true,
            },
          },
          {
            path: 'responsibility',
            name: 'Responsibility',
            component: Responsibility,
            meta: {
              step: 9,
              protected: true,
            },
          },
          {
            path: 'conclusion',
            name: 'Conclusion',
            component: Conclusion,
            meta: {
              step: 10,
              protected: true,
            },
          },
          {
            path: 'confirmation',
            name: 'Confirmation',
            component: Confirmation,
            meta: {
              step: 11,
              protected: true,
            },
          },
          {
            path: 'confirmed',
            name: 'Confirmed',
            component: Confirmed,
            meta: {
              step: 12,
              protected: true,
            },
          },
        ],
      },
      {
        path: ':catchAll(.*)',
        name: 'CatchAll',
        component: NotFound,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

// navigation guards
router.beforeEach(async (to, from, next) => {
  store.commit('incident/setLoading', true);

  if (!to.matched.some((match) => match.name === 'CatchAll')) {
    try {
      if (to.redirectedFrom?.name === 'JointAuth') {
        const authData: AuthRequestBody = {
          incidentIdentifier: to.redirectedFrom.params.incidentKey.toString(),
          incidentPartyIdentifier: to.redirectedFrom.params.partyKey.toString(),
        };
        setSelfCheckValue(authData.incidentIdentifier, authData.incidentPartyIdentifier);
        await store.dispatch('populateState', { authData });
      }

      if (to.meta.protected) {
        const authData: AuthRequestBody = {
          incidentIdentifier: to.params.incidentKey.toString(),
          incidentPartyIdentifier: to.params.partyKey.toString(),
        };

        if (!store.state.incident.key || store.state.parties.parties.length === 0) {
          await store.dispatch('populateState', { authData });
        } else {
          await authenticateTokens(authData);
        }

        const isHeadingToConfirmation = to.name === 'Confirmation';
        const isHeadingToConfirmed = to.name === 'Confirmed';
        const isIncidentEditable = store.state.incident.status === 'PENDING' || store.state.incident.status === 'DRAFT';
        const party: Party | undefined = store.getters['parties/getParty'](authData.incidentPartyIdentifier);

        const canEditData = () => {
          const dayAfterIncidentStart = new Date(store.state.incident.createdAt);
          dayAfterIncidentStart.setDate(dayAfterIncidentStart.getDate() + 1);

          // Has been more than a day from when the incident was opened
          if (dayAfterIncidentStart < new Date()) {
            throw new Error('Incident past due');
          }

          if (
            isHeadingToConfirmation
            && !party?.cascoInsurerId
            && !party?.handlingInsurerId
            && !party?.skippedInsurer
          ) {
            return true;
          }

          if (
            (party?.cascoInsurerId || party?.handlingInsurerId || party?.skippedInsurer)
            && isHeadingToConfirmed
          ) {
            return true;
          }

          if (party) {
            return (party.status === 'PENDING' || party.status === 'DRAFT') && isIncidentEditable;
          }
          return isIncidentEditable;
        };

        if (typeof Cookies.get('GDPR-personal-data-consent') === 'undefined' && to.name) {
          console.debug('No-GDPR consent. Redirecting to Index');

          return next({
            replace: true,
            name: 'Index',
            params: to.params,
            query: {
              redirectTo: to.name.toString(),
            },
          });
        } else if (
          isHeadingToConfirmation && (
            party?.cascoInsurerId || party?.handlingInsurerId || party?.skippedInsurer
          )
        ) {
          return next({
            replace: true,
            name: 'Confirmed',
            params: to.params,
          });
        } else if (!canEditData() && to.name !== 'Conclusion') {
          console.debug('Incident is not editable. Redirecting to Conclusion.');
          return next({
            replace: true,
            name: 'Conclusion',
            params: to.params,
          });
        }
      }
    } catch (e) {
      console.info('Route protected. Redirecting to 404');
      console.error(e);
      return next({
        replace: true,
        name: 'CatchAll',
        params: {
          locale: to.params.locale,
          catchAll: '404',
        },
      });
    }
    const nextResp = await maybeUpdateLang(to, from);
    if (nextResp) {
      return next(nextResp);
    }
  }
  return next();
});

export default router;
