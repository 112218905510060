<template>
  <nav>
    <hr class="divider--green">
    <div class="header__bottom">
      <!-- Container div so that the image scales properly-->
      <div>
        <img
          :alt="t('header.siteLogo')"
          class="header__logo"
          src="/logo.png"
        >
      </div>
      <div>
        <LKFSelect
          :default-option="selectedLocale"
          :options="selectOptions"
          class="header__lang-select"
          no-border
          not-searchable
          placeholder="Keel"
          select-name="langSelect"
          @change="onLanguageChange"
        />
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import Cookies from 'js-cookie';
import { SUPPORTED_LOCALES } from '@/i18n';
import LKFSelect from '@/components/LKFSelect.vue';
import { SelectOption } from '@/@types/selectOptions';

export default defineComponent({
  name: 'LKFHeader',
  components: {
    LKFSelect,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const { push } = useRouter();

    const selectOptions: SelectOption[] = SUPPORTED_LOCALES.map(
      ({ short, long }) => ({
        value: short,
        label: long,
      }),
    );

    // Get the selected locale, if none selected fall back to DEFAULT_LOCALE
    const selectedLocale: SelectOption = selectOptions.find(
      ({ value }) => (value === (route.params?.locale as string)?.toLowerCase()),
    ) ?? { value: process.env.VUE_APP_DEFAULT_LOCALE, label: process.env.VUE_APP_DEFAULT_LOCALE };

    // The on change handler
    const onLanguageChange = (newLocale: string) => {
      Cookies.set('selectedLang', newLocale);
      push({ name: route.name ?? 'catchall', params: { locale: newLocale } });
    };

    return {
      t,
      selectOptions,
      selectedLocale,
      onLanguageChange,
    };
  },
});
</script>
