import { RouteLocationNormalized } from 'vue-router';
import Cookies from 'js-cookie';
import i18n, { setI18nLanguage, SUPPORTED_LOCALES } from '@/i18n';

export const maybeUpdateLang = async (
  to: RouteLocationNormalized, from: RouteLocationNormalized,
): Promise<string> => {
  // Evaluate whether or not the URL contains a language
  const hasLang = !!(to.params && to.params.locale);
  const hadLang = !!(from.params && from.params.locale);
  // If the language hasn't changed since last route we're done
  if (
    hasLang
    && hadLang
    && from.params.locale?.toString().toLowerCase() === to.params.locale?.toString().toLowerCase()
  ) {
    return Promise.resolve('');
  }

  // Get the language
  let lang = process.env.VUE_APP_DEFAULT_LOCALE;

  if (navigator.language) {
    lang = navigator.language.slice(0, 2);
  }

  const cookieLang = Cookies.get('selectedLang');
  if (cookieLang) {
    lang = cookieLang;
  }

  // Overwrite the language with the route if there is one
  if (hasLang) {
    lang = to.params.locale.toString().toLowerCase();
  }

  let hasCorrectLang = true;
  // Make sure the language is valid
  if (!SUPPORTED_LOCALES.some(({ short }) => short === lang)) {
    lang = process.env.VUE_APP_DEFAULT_LOCALE;
    hasCorrectLang = false;
  }

  // Set the website language based on the URL
  await setI18nLanguage(i18n, lang);

  // Redirect to a url with the language
  if (!hasLang) {
    return Promise.resolve(`/${lang}${to.fullPath}`);
  }

  // If is a incorrect locale remove the old locale and substitute the new one.
  if (!hasCorrectLang) {
    const [,, ...pathPieces] = to.fullPath.split('/');
    return Promise.resolve(`/${lang}/${pathPieces.join('/')}`);
  }

  return Promise.resolve('');
};

export default {
  maybeUpdateLang,
};
