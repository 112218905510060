import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, normalizeClass as _normalizeClass, createVNode as _createVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "lkf-modal__header_title" }
const _hoisted_2 = { class: "lkf-modal__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LKFButton = _resolveComponent("LKFButton")!

  return (_ctx.isModalDisplayed)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "lkf-modal--background",
        ref: "modal",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.modalBgClose && _ctx.modalBgClose(...args)))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([{'lkf-modal--header-hidden': (!_ctx.title && !_ctx.isCloseable)}, "lkf-modal max--width"])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass([{'lkf-modal__header--no-title': (!_ctx.title && _ctx.isCloseable)}, "lkf-modal__header"])
          }, [
            _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.title), 1),
            _withDirectives(_createVNode(_component_LKFButton, {
              class: _normalizeClass([{'lkf-modal__header_button--big' : _ctx.title && _ctx.isCloseable}, "lkf-modal__header_button"]),
              "icon-name": "x",
              "subtle-icon-btn": "",
              variant: "icon",
              onClick: _ctx.closeModal
            }, null, 8, ["class", "onClick"]), [
              [_vShow, _ctx.isCloseable]
            ])
          ], 2),
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ], 2)
      ], 512))
    : _createCommentVNode("", true)
}